// DashboardCard.js

import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ForumIcon from '@mui/icons-material/Forum';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import styles from './dashboardCard.module.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const DashboardCard = ({ title, total, icon, percentageChange, thisWeekCount, gradientColors }) => {

    const cardStyle = {
        background: gradientColors || 'inherit',

    };



    return (
        <Card className={styles["dashboard-cards"]} sx={{ boxShadow:"none",display: 'flex', alignItems: 'center', borderRadius: "10px" }}>
            <CardContent className={styles.card_content}>
                <div className={styles.card_content_div}>
                    <div className={styles.card_content_text_container}>
                        <span className={styles["total-chats"]}>{title}</span>
                        <span className={styles.total} >{total}</span>
                    </div>
                    <div style={{ ...cardStyle }} className={styles["icon-card"]}>
                        {icon}
                    </div>

                </div>
                {/* <div className={styles["divider-card"]}></div> */}
                <div className={styles["percentage-div"]}>
                    <p className={styles["percentage-text"]} style={{margin:"0px"}}>
                        {thisWeekCount > 0 ? (
                            <span className={styles["green-text"]}>+{thisWeekCount}</span>
                        ) : (
                            <span className={styles["red-text"]}>{thisWeekCount}</span>
                        )}
                        <span className={styles.ago_text} > than last week</span>
                    </p>

                </div>

            </CardContent>
        </Card>
    );
};

export default DashboardCard;
