// Sidebar.js (Create a new component for the sidebar)


import React, { useState,useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import History from '../Sidebar_utils/History/History'; // Import the History component
import Visitors from '../Sidebar_utils/Visitors/Visitors'; // Import the Visitors component
import ActiveChat from '../Sidebar_utils/ActiveChat/ActiveChat';
import styles from "./sidebar.module.css"
import { useParams } from 'react-router-dom';


function Sidebar({ SelectedActiveChatuser, page, handleSearch, getSelectedUser, isFiltered, filteredUsers, filteredUsersOnline, connectedUsers, disconnectedUserList }) {
  const params = useParams();
  const [pageSub, setPageSub] = useState("");


  const capitalizeFirstWord = (str) => {
    if (!str) return ''; // Handle the case when 'str' is empty or undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    console.log("params......", params)

    if (params.chatType === "allchats") {
      setPageSub("All Chats");
    } else if (params.chatType === "closedchats") {
      setPageSub("Closed Chats");
    } else if (params.chatType === "missedchats") {
      setPageSub("Missed Chats");
    } else if (params.chatType === "archivedchats") {
      setPageSub("Archived Chats");
    }
    else if (params.chatType === "offlinechats") {
      setPageSub("Offline Chats");
    }
  }, [params.chatType]);
  

  return (
    <>

      {page !== "BotFlow" && (
        <div className={styles['main-sidebar']}>
          <div style={{ marginTop: "14px", marginBottom: "-4px" }}>
            <span className={styles['pageText']}>{capitalizeFirstWord(page === "history" ? pageSub : page)}</span>
          </div>
          <div className={`${styles['search-bar']} m-3`}>
            <span className={styles["search-icon"]}>
              <BsSearch />
            </span>
            <input
              type="text"
              placeholder="Search People or Message"
              className={styles["search-input"]}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className={styles['main-sidebar-content']}>
            {page === 'history' ? (
              <History pageSub={pageSub} connectedUsers={isFiltered ? filteredUsers : disconnectedUserList} selectUser={getSelectedUser} />
            ) : page === 'visitor' ? (
              <Visitors connectedUsers={isFiltered ? filteredUsers : disconnectedUserList} selectUser={getSelectedUser} />
            ) : (
              // <ActiveChat  isActiveChatUserList={isActiveChatUserList} connectedUsers={isFiltered ? filteredUsersOnline : connectedUsers} selectUser={getSelectedUser} />

              <ActiveChat SelectedActiveChatuser={SelectedActiveChatuser} connectedUsers={connectedUsers} selectUser={getSelectedUser} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
