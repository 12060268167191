import React, { createContext, useContext, useState } from 'react';

const ConnectedUsersContext = createContext();

export const ConnectedUsersProvider = ({ children }) => {
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [disconnectedUsers, setDisconnectedUsers] = useState([]);

  const updateConnectedUsers = (newArray) => {
    setConnectedUsers(newArray);
  };

  const updateDisconnectedUsers = (newArray) => {
    setDisconnectedUsers(newArray);
  };

  return (
    <ConnectedUsersContext.Provider value={{ setDisconnectedUsers,setConnectedUsers,connectedUsers, updateConnectedUsers,disconnectedUsers,updateDisconnectedUsers }}>
      {children}
    </ConnectedUsersContext.Provider>
  );
};

export const useConnectedUsersContext = () => {
  return useContext(ConnectedUsersContext);
};
