import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import ReactFlow, { addEdge, useEdgesState, useNodesState, MarkerType } from 'reactflow';
import 'reactflow/dist/style.css';
import { FaEnvelope } from 'react-icons/fa'; // Import the email icon
import { MdOutlineShortText, MdOutlineKeyboardArrowDown, MdOutlineEmail } from "react-icons/md";
import { BsCheck2 } from "react-icons/bs";
import { TiInputChecked } from "react-icons/ti";


function Flow(props) {
  const [cardList, setCardList] = useState(props.cardList);

  const initialEdges = [];
  const initialNodes = [];

  cardList.forEach((item, index) => {
    if (item.logic) {
      item.logic.forEach((logicItem) => {
        const targetIndex = cardList.findIndex((q) => q.question_id === logicItem.question_id);
        if (targetIndex !== -1) {
          const edge = {
            id: `e${index + 1}-${targetIndex + 1}`,
            source: (index + 1).toString(),
            target: (targetIndex + 1).toString(),
            label: logicItem.option,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: '#a66a6',
            },
            style: {
              strokeWidth: 1,
              stroke: '#a6a6a6',
            },
          };
          initialEdges.push(edge);
        }
      });
    }

    // Conditionally determine the icon based on the question type
    let icon = null;
    if (item.questionType === 'Email') {
      icon = <FaEnvelope />;
    } else if (item.questionType === 'Multiple Choice') {
      icon = <TiInputChecked />;
    } else if (item.questionType === 'Email') {
      icon = <FaEnvelope />; // Use the email icon for email type questions
    }else if (item.questionType === 'Dropdown') {
      icon = <MdOutlineKeyboardArrowDown />; // Use the email icon for email type questions
    }else if (item.questionType === 'Short Text') {
      icon = <MdOutlineShortText />; // Use the email icon for email type questions
    } else {
      icon = <BsCheck2 />
    }

    const node = {
      id: (index + 1).toString(),
      data: {
        label: (
          <div style={{ display: "flex", alignItems: "center", borderRadius: "5px" }}>
            <div style={{
              padding: '3px 4px 3px 4px',
              alignItems: 'center',
              display: 'flex',
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              backgroundColor: item.questionType === "Multiple Choice" ? "rgb(214, 92, 153)" :
                item.questionType === "Statement" ? "rgb(55, 156, 251)" :
                  item.questionType === "Short Text" ? "rgb(251, 161, 55)" :
                    item.questionType === "Dropdown" ? "cadetblue" :
                      item.questionType === "Email" ? "rgb(155, 206, 253)" : "inherit",
              color: 'black',
              fontSize: '14px',
              borderRadius: '24%',
              marginRight: '7px'

            }}>
              {icon}
            </div>

            {item.content}
          </div>

        ),
      },
      position: {
        x: 250 + (index % 2) * 400,
        y: 50 + Math.floor(index / 2) * 190,
      },
      style: {
        background: 'white',
        boxShadow: "rgb(179 179 179 / 84%) -7px 5px 18px 1px",
      },
      sourcePosition: 'right',
      targetPosition: 'left',
    };

    initialNodes.push(node);
  });

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
    />
  );
}

export default Flow;
