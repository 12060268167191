import React, { useState, useEffect, useMemo, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styles from "./basicTable.module.css"
import { FaUserCircle } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { deleteUser } from '../../../../../Service/API';
import { useAuth } from '../../../../../Service/AuthContext';
import { Backdrop, Box, Button, CircularProgress, Fade, Modal, Typography } from '@mui/material';
import SuccessSnackbar from '../../../../../Components/SuccessSnackbar/SuccessSnackbar';
import ErrorSnackbar from '../../../../../Components/ErrorSnackbar/ErrorSnackbar';
import { IoClose } from 'react-icons/io5';
import { MdEmail } from "react-icons/md";
// import EmailSvg from './Icon/EmailSvg';
// import emailSvg from "./email-svgrepo-com.svg"


// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

const styleConfirmModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 200,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function BasicTable({ setOperatorAdded, operatorAdded, operatorsList, organizationName }) {
    const { widgetID } = useAuth();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [deletedOperator, setDeletedOperator] = useState("")
    const [changesInProgress, setChangesInProgress] = useState(false)
    const [isSnackbarOpen, setIsSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isModalOpen, setModalOpen] = useState(false);
    const modalRef = useRef();



    const handleDeleteUser = async (email) => {
        console.log("userData", email, widgetID);

        const userData = {
            email: email,
            widgetID: widgetID,
        };
        handleModalClose()

        setChangesInProgress(true)

        try {
            const response = await deleteUser(userData);
            console.log(response.message);
            console.log(response);


            if (response.message === "User deleted successfully") {
                setOperatorAdded(!operatorAdded)
            }
            setIsSnackbarOpen({ isOpen: true, message: "User deleted successfully..." })
            setChangesInProgress(false)
        } catch (error) {
            console.error("Error deleting user:", error);
            setChangesInProgress(false)
            setIsErrorSnackbarOpen({ isOpen: true, message: "Something went wrong in deleting user..." })


        }
    };

    const onClose = () => {
        setModalOpen(!isModalOpen)
    }


    const handleModalClose = () => {
        setOpenConfirm(false);
    };

    const handleDeleteBtn = (operator) => {
        setDeletedOperator(operator)
        setOpenConfirm(!openConfirm)
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if (isModalOpen) onClose();
            }
        };

        // Add event listener to handle clicks outside of modal
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);



    return (
        <TableContainer component={Paper} className={styles.table_container} style={{ maxHeight: "420px" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={styles.table_heading}>Name</TableCell>
                        <TableCell className={styles.table_heading}>Email</TableCell>
                        <TableCell className={styles.table_heading}>Role</TableCell>
                        <TableCell className={styles.table_heading}>Organisation</TableCell>
                        <TableCell className={styles.table_heading}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {operatorsList.map((operator) => (
                        <TableRow key={operator.email}>
                            <TableCell>
                                <div className={styles.owner}>
                                    <FaUserCircle style={{ width: "22px", height: "22px", color: "#5f5f5f" }} />
                                    {operator.name}
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={styles.owner}>{operator.email}</div>
                            </TableCell>
                            <TableCell>
                                <div className={styles.owner}>{operator.role}</div>
                            </TableCell>
                            <TableCell style={{ color: "#212121" }}>{organizationName}</TableCell>
                            <TableCell className={styles.tableCell}>
                                {operator.role === "Admin" ? null : (
                                    <MdDelete onClick={() => handleDeleteBtn(operator)} className={styles.deleteIcon} />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openConfirm}
                onClose={handleModalClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openConfirm}>
                    <Box sx={styleConfirmModal}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Alert
                        </Typography>
                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to save these changes?
                        </Typography>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button onClick={() => handleDeleteUser(deletedOperator.email)} variant="contained" color="primary">
                                Save
                            </Button>
                            <Button onClick={handleModalClose} variant="outlined" color="secondary" style={{ marginLeft: '1rem' }}>
                                Exit
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>



            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={changesInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <SuccessSnackbar isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
            <ErrorSnackbar isErrorSnackbarOpen={isErrorSnackbarOpen} setIsErrorSnackbarOpen={setIsErrorSnackbarOpen} />
        </TableContainer>
    );
}