import { Alert, Snackbar } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

export default function SuccessSnackbar({isSnackbarOpen, setIsSnackbarOpen}){

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSnackbarOpen({ isOpen: false, message: "" });
    };
    return (
        <Snackbar

        open={isSnackbarOpen.isOpen}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
        <Alert
            onClose={handleSnackbarClose}
            variant="filled"

            icon={<FaCheckCircle style={{ color: "green" }} fontSize="inherit" />}
            sx={{ width: '100%', backgroundColor: 'white', color: "black", fontWeight: "400", boxShadow: "0 0 10px rgb(83 83 83 / 55%)" }}

        >
            {isSnackbarOpen.message}
        </Alert>
    </Snackbar>
    )
}