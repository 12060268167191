import styles from "../forgotPassword.module.css"
import { MdFingerprint } from "react-icons/md";
import Button from '@mui/material/Button';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { FaCheckCircle } from "react-icons/fa";


export default function SuccessComponent({loading}){
    const navigate = useNavigate()

    return (
        <div className={styles.email_container}>
            <div className={styles.top_section}>
                <div className={styles.icon_container} style={{justifyContent:"start"}}>
                    <span className={styles.icon}>
                        <FaCheckCircle style={{ width: "20px", height: "20px" , color:"green" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p} style={{textAlign:"start"}}>All Done!</p>
                    <p style={{ color: "#8E8EA0", fontSize: "17px", textAlign: "start" }}>Your password has been reset successfully.</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={() => navigate("/login")} variant="contained" fullWidth>Login</Button>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}