import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './Service/AuthContext';
import { BrowserRouter as Router, Routes, Route, Navigate, Redirect } from 'react-router-dom';
import { UserProvider } from './Service/SelectedUserContext';
import { ConnectedUsersProvider } from './Service/ConnectedUsersContext';
import { SoundProvider } from './Service/SoundContext';
import { GlobalVariablesProvider } from './Service/GlobalVariables';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Router>
    <AuthProvider>
      <GlobalVariablesProvider>
        <ConnectedUsersProvider>
          <UserProvider>
            <SoundProvider>
              <App />
            </SoundProvider>
          </UserProvider>
        </ConnectedUsersProvider>
      </GlobalVariablesProvider>
    </AuthProvider>
  </Router>
  // </React.StrictMode>
);


     