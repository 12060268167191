import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [widgetID, setWidgetID] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);

  const setAuthData = (attributes) => {
    setUserAttributes(attributes);
  };

  const setWidgetData = (id) => {
    setWidgetID(id);
  };

  return (
    <AuthContext.Provider value={{ widgetID, userAttributes, setAuthData, setWidgetData,setUserAttributes }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
