import styles from "./BotFlow.module.css";
import { useState, useEffect } from 'react';
import Flow from "./BotFlow_components/FlowChart/FlowChart.js"
import socket from '../../socketjs.js';
import "bootstrap/dist/css/bootstrap.min.css";
import { TiInputChecked } from "react-icons/ti";
import { MdDelete, MdOutlineKeyboardArrowDown, MdOutlineEmail, MdOutlineShortText } from "react-icons/md";
import { BsPlusLg, BsCheck2, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { FiAlertTriangle } from "react-icons/fi";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import BotFlowAlerts from "./BotFlow_components/Alerts/BotFlowAlerts.js";
import BotFlowQuestionSidebar from './BotFlow_components/BotFlow_QuestionSidebar/BotFlowQuestionSidebar.js';
import BotFlowQuestionContent from './BotFlow_components/BotFlow_QuestionContent/BotFlowQuestionContent.js';
import { useAuth } from "../../Service/AuthContext.js";
import * as api from '../../Service/API.js'; // Import your API functions
import botflowImage from '../../Img/botflowImage.png'
import MenuSideBar from "../../Components/MenuSidebar/MenuSideBar.js";
import Navbar from "../../Components/Navbar/Navbar.js";
import CustomSelect from "../../Components/CustomSelect/CustomSelect.js";

const BotFlow = (props) => {

    const [FlowChart, setFlowChart] = useState(false);

    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertDuplicate, setshowAlertDuplicate] = useState(false);

    const [selectedCardIndex, setSelectedCardIndex] = useState(null);
    const [selectedCard, setSelectedCard] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [recordDelete, setrecordDelete] = useState(false);
    const [hasNonEmptyContentIndex, setHasNonEmptyContentIndex] = useState(-1);
    const [errorConditionDropDownIndex, setErrorConditionDropDownIndex] = useState(-1);
    const [errorConditionMultipleIndex, setErrorConditionMultipleIndex] = useState(-1);
    const [deleteQuestion, setDeleteQuestion] = useState(false);
    const [DeleteIndex, setDeleteIndex] = useState('');
    const [selectedBottomOption, setSelectedBottomOption] = useState('');
    const [choices, setChoices] = useState("");
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [customOptions, setCustomOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedLeftOption, setSelectedLeftOption] = useState('');
    const [selectedRightOption, setSelectedRightOption] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [active, setActive] = useState(true);
    const [RemoveLogicModal, setRemoveLogicModal] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [cardList, setCardList] = useState([]);

    const handleCloseAlert = () => setShowAlert(false)
    const handleCloseAlertDelete = () => setShowAlertDelete(false)
    const handleCloseDuplicate = () => setshowAlertDuplicate(false)

    const handleModalClose = () => setOpenConfirm(false);
    const handleClose = () => setOpen(false);
    const handleCloseRemoveModal = () => setRemoveLogicModal(false);
    const { widgetID, userAttributes } = useAuth();

    const handleClick = async () => {
        console.log("Testing")
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const botMessage = await api.fetchBotMessages(widgetID);
                setCardList(botMessage);
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchData();
    }, [widgetID]);

    const leftOptions = ['Is Not'];


    const styleConfirmModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 200,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleDeleteOption = (index) => {
        setDeleteQuestion(true)
        setDeleteIndex(index)
    };


    const handleBottomSelectChange2 = (e) => {
        setSelectedBottomOption(e.target.value);
    };

    const handleOpen = async (options) => {
        var result = options.join('\n');
        setChoices(result)
        setOpen(true)

    }

    const handleSave = (cardIndex) => {

        const newOptions = choices.split('\n').map(option => option.trim());


        const updatedCardList = [...cardList];


        updatedCardList[cardIndex].options = newOptions;

        const card = updatedCardList[cardIndex];


        const newOptionsSet = new Set(newOptions.map(option => option.toLowerCase()));


        if (card && Array.isArray(card.logic)) {
            // Use the filter method here
            card.logic = card.logic.filter(logicItem => {
                const lowerCaseOption = logicItem.option.toLowerCase();
                return lowerCaseOption === "for all options" || newOptionsSet.has(lowerCaseOption);
            });
        } else {
            console.error("card.logic is not defined or is not an array.");
        }



        setCardList(updatedCardList);
        setCustomOptions(newOptions);



        handleClose();
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleActive = () => {
        setActive(true);
    };

    const handleNonActive = () => {
        setActive(false);

    };

    const toggleSaveRule = () => {

        cardList.forEach(card => {

            if (selectedCard.some(selected => selected.question_id === card.question_id)) {
                // Check if the card has a logic object
                if (!card.logic) {
                    card.logic = [];
                    card.logic.push({
                        logicId: generateUniqueLogicId(),
                        question_id: selectedBottomOption,
                        option: selectedRightOption,
                        isEditable: false
                    });
                } else {

                    const existingLogic = card.logic.find(obj => obj.option === selectedRightOption);
                    if (existingLogic) {
                        // If it exists, update its question_id
                        existingLogic.question_id = selectedBottomOption;
                    } else {
                        // If it doesn't exist, add a new object
                        card.logic.push({
                            logicId: generateUniqueLogicId(),
                            question_id: selectedBottomOption,
                            option: selectedRightOption,
                            isEditable: false
                        });
                    }
                }
            }
        });

        console.log("Updated cardList", cardList);
        setSelectedBottomOption('')
        setSelectedRightOption('')

        setIsExpanded(false)

    };

    const toggleDeleteRule = () => {
        setIsExpanded(false)
    };

    const openConfirmModal = () => {
        setOpenConfirm(true)
    };

    const toggleCard = () => {
        setIsExpanded(!isExpanded);
    };

    const handleDeleteQuestion = () => {
        setDeleteQuestion(false);
    };

    const handleDeleteOptionConfirm = () => {
        const updatedCardList = [...cardList];
        const deletedItem = updatedCardList.splice(DeleteIndex, 1)[0];

        setCardList(updatedCardList);


        if (deletedItem.question_id) {
            updatedCardList.forEach(item => {
                if (Array.isArray(item.logic)) {
                    item.logic = item.logic.filter(logicItem => logicItem.question_id !== deletedItem.question_id);
                }
            });
            setCardList(updatedCardList);
        }

        setShowAlertDelete(true);
        setrecordDelete(true)
        setDeleteQuestion(false)
    };

    function generateUniqueLogicId() {
        // Create a timestamp-based ID combined with a random number
        const timestamp = new Date().getTime();
        const random = Math.random().toString(36).substring(2, 10); // Random string of 8 characters
        const uniqueId = `logic_${timestamp}${random}`;

        return uniqueId;
    }

    const SendRuleToServer = () => {
        const hasNonEmptyContentIndex = cardList.findIndex(item => !item.content.trim());
        const errorConditionMultipleIndex = cardList.findIndex(item =>
            item.questionType === 'Multiple Choice' && item.options.every(option => option.trim() === '')
        );
        const errorConditionDropDownIndex = cardList.findIndex(item =>
            item.questionType === 'Dropdown' && item.options.length === 0
        );

        setHasNonEmptyContentIndex(hasNonEmptyContentIndex);
        setErrorConditionMultipleIndex(errorConditionMultipleIndex);
        setErrorConditionDropDownIndex(errorConditionDropDownIndex);

        if (hasNonEmptyContentIndex >= 0 || errorConditionMultipleIndex >= 0 || errorConditionDropDownIndex >= 0) {
            setOpenConfirm(false);
            handleModalErrorOpen();
        } else {
            handleModalClose();
            setShowAlert(true);
            socket.emit('logic_to_server', {
                LogicArray: cardList,
                widgetID: widgetID,
            });
        }
    };

    const handleModalErrorOpen = () => {
        setOpenError(true);
    };

    const handleModalErrorClose = () => {
        setOpenError(false);
    };

    const toggleRemoveRule = () => {
        setRemoveLogicModal(true)
    };

    const ConfirmRemoveRule = () => {

        selectedCard[0].logic = []
        const index = cardList.findIndex(card => card.question_id === selectedCard.question_id);
        if (index !== -1) {
            cardList[index].logic = [];
        }
        setRemoveLogicModal(false)
    };

    function handleLeftSelectChange(event) {
        const selectedOption = event.target.value;
        setSelectedLeftOption(selectedOption)
    }

    function handleRightSelectChange(event) {

        if (event) {
            const selectedOption = event.value;

            setSelectedRightOption(selectedOption)
        } else {
            setSelectedRightOption('')
        }
    }

    function handleBottomSelectChange(event) {
        if (event) {
            const selectedOption = event.value;
            setSelectedBottomOption(selectedOption)
        } else {
            setSelectedBottomOption('')
        }

    }

    const filteredCardList = cardList.filter((card) => !selectedCard.some((selected) => selected.question_id === card.question_id));


    const SavedRuleList = selectedCard ? (
        selectedCard.map((pdata) => (
            <div key={pdata.id}>
                {pdata.logic && Array.isArray(pdata.logic) ? (
                    pdata.logic.map((logicItem, index) => {




                        const matchingQuestion = cardList.find(card => card.question_id === logicItem.question_id);
                        const questionIndex = matchingQuestion ? cardList.indexOf(matchingQuestion) : null;
                        const Selected_QuestionType = matchingQuestion ? matchingQuestion.questionType : null;

                        // Check if pdata.options is an array and logicItem.option is a valid index
                        console.log("logicItem.option", logicItem.option)
                        console.log("pdata.options", pdata.options)
                        const optionIndex = Array.isArray(pdata.options) ? pdata.options.indexOf(logicItem.option) : -1;
                        console.log("optionIndex", optionIndex)

                        return (
                            <div className={styles["Render-rules-list"]} key={index}>
                                {pdata.questionType == "Multiple Choice" && (
                                    <div style={{ display: "flex" }}>
                                        <p style={{ marginTop: "2px" }}>If</p>
                                        <p style={{ marginLeft: "5px", border: "1px solid black", paddingLeft: "7px", paddingRight: "7px" }}>{optionIndex !== -1 ? String.fromCharCode(65 + optionIndex) : 'Not found'}</p>
                                    </div>

                                )}

                                {pdata.questionType === "Dropdown" && (
                                    logicItem.option !== "For all options" ? (
                                        <div style={{ display: "flex" }}>
                                            <p style={{ marginTop: "2px" }}>If</p>
                                            <p style={{ marginLeft: "5px", border: "1px solid black", paddingLeft: "7px", paddingRight: "7px" }}>{logicItem.option}</p>
                                        </div>
                                    ) : (
                                        <div style={{ display: "flex" }}>
                                            <p style={{ marginTop: "2px" }}>All other cases</p>

                                        </div>
                                    )
                                )}


                                {(pdata.questionType !== "Multiple Choice" && pdata.questionType !== "Dropdown") && (
                                    <div style={{ display: "flex" }}>
                                        <p style={{ marginTop: "2px" }}>Always</p>
                                    </div>

                                )}
                                <div style={{ display: "flex" }}>
                                    <p style={{ marginTop: "2px" }}>go to</p>


                                    <div
                                        style={{
                                            marginLeft: "7px",

                                            display: "flex",

                                            alignItems: "center",
                                            backgroundColor:
                                                Selected_QuestionType === "Multiple Choice"
                                                    ? "rgb(214, 92, 153)"
                                                    : Selected_QuestionType === "Statement"
                                                        ? "rgb(55, 156, 251)"
                                                        : Selected_QuestionType === "Short Text" ? "rgb(251, 161, 55)"
                                                            : Selected_QuestionType === "Dropdown" ? "cadetblue"
                                                                : Selected_QuestionType === "Email" ? "rgb(155, 206, 253)" : "inherit",


                                            color:
                                                Selected_QuestionType === "Multiple Choice"
                                                    ? "black"
                                                    : "inherit",
                                            padding: "9px",
                                            height: "24px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >

                                        <div style={{ marginRight: "16px" }}>
                                            {Selected_QuestionType === "Multiple Choice" ? (
                                                <TiInputChecked />
                                            ) : Selected_QuestionType === "Dropdown" ? (
                                                <MdOutlineKeyboardArrowDown />
                                            ) : Selected_QuestionType === "Email" ? (
                                                <MdOutlineEmail />
                                            ) : Selected_QuestionType === "Short Text" ? (
                                                <MdOutlineShortText />
                                            ) : (
                                                <BsCheck2 />
                                            )}




                                        </div>
                                        <div>{questionIndex + 1}</div>
                                    </div>
                                </div>





                            </div>
                        );


                    })
                ) : (
                    // Handle the case when pdata.logic is undefined or not an array
                    null
                )}
            </div>
        ))
    ) : (
        <p>No selected card data available.</p>
    );

    // render list
    const renderedList = selectedCard.map((pdata) => (
        <div key={pdata.question_id} className={styles['side-bar']} style={{ zIndex: "111", position: "relative" }}>

            <div className={sidebarVisible ? styles["right-sidebar-close"] : styles["right-sidebar-open"]}>
                {!sidebarVisible && (
                    <div className={styles['toggle-btn-visitor']}>
                        <span
                            className={active ? styles['active-visitor'] : styles['non-active-visitor']}
                            onClick={handleActive}
                        >
                            Logic
                        </span>
                        <span
                            className={!active ? styles['active-visitor'] : styles['non-active-visitor']}
                            onClick={handleNonActive}
                        >
                            Design
                        </span>
                    </div>
                )}

                {!recordDelete && (
                    <div>
                        {!active ? (

                            null
                        ) : (
                            <div>

                                <div style={{ marginLeft: "16px", marginTop: "15px", display: "flex", flexFlow: "row" }}>
                                    <p style={{ fontWeight: "500" }}>Edit logic for</p>
                                    <div
                                        style={{
                                            marginLeft: "16px",

                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor:
                                                pdata.questionType === "Multiple Choice"
                                                    ? "rgb(214, 92, 153)"
                                                    : pdata.questionType === "Statement"
                                                        ? "rgb(55, 156, 251)"
                                                        : pdata.questionType === "Short Text"
                                                            ? "rgb(251, 161, 55)"
                                                            : pdata.questionType === "Dropdown" ? "cadetblue"
                                                                : pdata.questionType === "Email" ? "rgb(155, 206, 253)" : "inherit",

                                            color:
                                                pdata.questionType === "Multiple Choice"
                                                    ? "black"
                                                    : "inherit",
                                            padding: "9px",
                                            height: "24px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >

                                        <div style={{ marginRight: "16px" }}>
                                            {pdata.questionType === "Multiple Choice" ? (
                                                <TiInputChecked />
                                            ) : pdata.questionType === "Dropdown" ? (
                                                <MdOutlineKeyboardArrowDown />
                                            ) : pdata.questionType === "Email" ? (
                                                <MdOutlineEmail />
                                            ) : pdata.questionType === "Short Text" ? (
                                                <MdOutlineShortText />
                                            ) : (
                                                <BsCheck2 />
                                            )}




                                        </div>
                                        <div>{selectedCardIndex + 1}</div>
                                    </div>
                                </div>
                                {/* )} */}

                                <div style={{ overflowY: "scroll", height: "66vh" }}>

                                    {pdata.logic ? (
                                        <div className={styles["card-container"]}>
                                            {SavedRuleList}
                                        </div>
                                    ) : null}

                                    {(isExpanded && pdata.questionType === "Multiple Choice") && (
                                        <div className={styles['Rule-card-header']}>

                                            <div>

                                                <div className={styles["dropdown-container"]}>
                                                    <div style={{ display: "flex" }}>
                                                        <label style={{ marginRight: "5px", marginTop: "10px" }} htmlFor="top-dropdown">If:</label>
                                                        <div style={{ alignItems: "center", width: "90%", display: "flex", flexFlow: "row", backgroundColor: "white", border: "1px solid #cccccc", borderRadius: "5px", padding: "5px" }}>

                                                            <div
                                                                style={{


                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    backgroundColor:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "rgb(214, 92, 153)"
                                                                            : pdata.questionType === "Statement"
                                                                                ? "rgb(55, 156, 251)"
                                                                                : pdata.questionType === "Short Text"
                                                                                    ? "rgb(251, 161, 55)"
                                                                                    : pdata.questionType === "Dropdown" ? "cadetblue"
                                                                                        : pdata.questionType === "Email" ? "rgb(155, 206, 253)" : "inherit",
                                                                    color:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "black"
                                                                            : "inherit",
                                                                    padding: "9px",
                                                                    height: "24px",
                                                                    borderRadius: "8px",
                                                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >

                                                                <div style={{ marginRight: "16px" }}>
                                                                    {pdata.questionType === "Multiple Choice" ? (
                                                                        <TiInputChecked />
                                                                    ) : pdata.questionType === "Dropdown" ? (
                                                                        <MdOutlineKeyboardArrowDown />
                                                                    ) : pdata.questionType === "Email" ? (
                                                                        <MdOutlineEmail />
                                                                    ) : pdata.questionType === "Short Text" ? (
                                                                        <MdOutlineShortText />
                                                                    ) : (
                                                                        <BsCheck2 />
                                                                    )}
                                                                </div>
                                                                <div>{selectedCardIndex + 1}</div>
                                                            </div>
                                                            <div style={{ marginLeft: "5px", fontSize: "13px", display: "flex", alignItems: "center" }}>{pdata.content.length > 23 ? pdata.content.slice(0, 20) + "..." : pdata.content}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "30px" }} className={styles["dropdown-container"]}>

                                                        <select
                                                            id="left-dropdown"
                                                            className={styles['dropdown-padding']}
                                                            value={selectedLeftOption}
                                                            onChange={handleLeftSelectChange}
                                                            style={{ width: "70px", border: "1px solid #e3e3e3;", borderRadius: "10px" }}

                                                        >
                                                            <option value="is">is</option>
                                                            {/* {leftOptions.map((option, index) => (
                                                                <option key={index} value={option}>
                                                                    {option}
                                                                </option>
                                                            ))} */}
                                                        </select>
                                                    </div>

                                                    <div className={styles["dropdown-container"]}>
                                                        {/* <label htmlFor="right-dropdown">Dropdown 3:</label> */}
                                                        <CustomSelect
                                                            onChange={handleRightSelectChange}
                                                            options={pdata.options.map(option => ({ value: option, label: option }))} // Convert options array to format expected by react-select
                                                            value={selectedRightOption ? { value: selectedRightOption, label: selectedRightOption } : null} // Convert selectedRightOption to format expected by react-select
                                                            width="191px"
                                                            placeholder="Select an option" // Placeholder text
                                                            showError={false} // Set to true if you want to show an error message
                                                        />
                                                    </div>
                                                </div>


                                                <div className={styles["dropdown-container"]}>
                                                    <label style={{ marginRight: "5px" }} htmlFor="bottom-dropdown">then go to:</label>

                                                    <CustomSelect
                                                        onChange={handleBottomSelectChange} // Pass your onChange handler function
                                                        width="60%" // Customize the width if needed
                                                        options={filteredCardList.map((option) => ({
                                                            value: option.question_id,
                                                            label: option.content,
                                                        }))} // Map your options to match the format expected by react-select
                                                        placeholder="Select an option" // Set the placeholder text
                                                        value={selectedBottomOption} // Pass the selected value
                                                        showError={false} // Set to true if you want error handling
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className={styles["dropdown-container"]}>
                      <label htmlFor="card-dropdown">All other cases goes to:   </label>
                      <select
                        id="card-dropdown"
                        value={selectedCard}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select a card</option>
                       
                      </select>
                    </div> */}

                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {pdata.questionType == "Multiple Choice" && (
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <button
                                                            onClick={toggleSaveRule}
                                                            className={`${styles['Addrule-button-sidebar']}${(selectedRightOption === "" || selectedBottomOption === "") ? ' disabled' : ''}`}
                                                            disabled={(selectedRightOption === "" || selectedBottomOption === "")}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                )}

                                                <span onClick={toggleDeleteRule} className={styles["delete-this-rule"]}>Delete this rule</span>
                                            </div>

                                        </div>
                                    )}

                                    {(isExpanded && pdata.questionType !== "Multiple Choice" && pdata.questionType !== "Dropdown") && (
                                        <div className={styles['Rule-card-header']}>

                                            <div>

                                                <div className={styles["dropdown-container"]}>
                                                    <div style={{ display: "flex" }}>
                                                        <label style={{ marginRight: "5px", marginTop: "10px" }} htmlFor="top-dropdown">If:</label>
                                                        <div style={{ alignItems: "center", width: "90%", display: "flex", flexFlow: "row", backgroundColor: "white", border: "1px solid #cccccc", borderRadius: "5px", padding: "5px" }}>

                                                            <div
                                                                style={{


                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    backgroundColor:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "rgb(214, 92, 153)"
                                                                            : pdata.questionType === "Statement"
                                                                                ? "rgb(55, 156, 251)"
                                                                                : pdata.questionType === "Short Text"
                                                                                    ? "rgb(251, 161, 55)"
                                                                                    : pdata.questionType == "Dropdown" ? "cadetblue"
                                                                                        : pdata.questionType == "Email" ? "rgb(155, 206, 253)" : "inherit",
                                                                    color:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "black"
                                                                            : "inherit",
                                                                    padding: "9px",
                                                                    height: "24px",
                                                                    borderRadius: "8px",
                                                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >

                                                                <div style={{ marginRight: "16px" }}>
                                                                    {pdata.questionType === "Multiple Choice" ? (
                                                                        <TiInputChecked />
                                                                    ) : pdata.questionType === "Dropdown" ? (
                                                                        <MdOutlineKeyboardArrowDown />
                                                                    ) : pdata.questionType === "Email" ? (
                                                                        <MdOutlineEmail />
                                                                    ) : pdata.questionType === "Short Text" ? (
                                                                        <MdOutlineShortText />
                                                                    ) : (
                                                                        <BsCheck2 />
                                                                    )}
                                                                </div>
                                                                <div>{selectedCardIndex + 1}</div>
                                                            </div>
                                                            <div style={{ marginLeft: "5px", fontSize: "13px", display: "flex", alignItems: "center" }}>{pdata.content.length > 23 ? pdata.content.slice(0, 20) + "..." : pdata.content}</div>
                                                        </div>
                                                    </div>
                                                </div>





                                            </div>
                                            <div className={styles["dropdown-container"]}>
                                                <label htmlFor="card-dropdown">Always goes to:   </label>
                                                <CustomSelect
                                                    onChange={handleBottomSelectChange} // Pass your onChange handler function
                                                    width="60%" // Customize the width if needed
                                                    options={filteredCardList.map((option) => ({
                                                        value: option.question_id,
                                                        label: option.content,
                                                    }))} // Map your options to match the format expected by react-select
                                                    placeholder="Select an option" // Set the placeholder text
                                                    value={selectedBottomOption} // Pass the selected value
                                                    showError={false} // Set to true if you want error handling
                                                />
                                            </div>



                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                <div style={{ marginLeft: "10px" }}>
                                                    <button
                                                        onClick={toggleSaveRule}
                                                        className={`${styles['Addrule-button-sidebar']}${(selectedBottomOption === "") ? ' disabled' : ''}`}
                                                        disabled={selectedBottomOption === ""}
                                                    >
                                                        Save
                                                    </button>
                                                </div>






                                                <span onClick={toggleDeleteRule} className={styles["delete-this-rule"]}>Delete this rule</span>
                                            </div>

                                        </div>
                                    )}


                                    {(isExpanded && pdata.questionType === "Dropdown") && (
                                        <div className={styles['Rule-card-header']}>

                                            <div>

                                                <div className={styles["dropdown-container"]}>
                                                    <div style={{ display: "flex" }}>
                                                        <label style={{ marginRight: "5px", marginTop: "10px" }} htmlFor="top-dropdown">If:</label>
                                                        <div style={{ alignItems: "center", width: "90%", display: "flex", flexFlow: "row", backgroundColor: "white", border: "1px solid #cccccc", borderRadius: "5px", padding: "5px" }}>

                                                            <div
                                                                style={{


                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    backgroundColor:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "rgb(214, 92, 153)"
                                                                            : pdata.questionType === "Statement"
                                                                                ? "rgb(55, 156, 251)"
                                                                                : pdata.questionType === "Short Text"
                                                                                    ? "rgb(251, 161, 55)"
                                                                                    : pdata.questionType === "Dropdown" ? "cadetblue"
                                                                                        : pdata.questionType === "Email" ? "rgb(155, 206, 253)" : "inherit",
                                                                    color:
                                                                        pdata.questionType === "Multiple Choice"
                                                                            ? "black"
                                                                            : "inherit",
                                                                    padding: "9px",
                                                                    height: "24px",
                                                                    borderRadius: "8px",
                                                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                                                                }}
                                                            >

                                                                <div style={{ marginRight: "16px" }}>
                                                                    {pdata.questionType === "Multiple Choice" ? (
                                                                        <TiInputChecked />
                                                                    ) : pdata.questionType === "Dropdown" ? (
                                                                        <MdOutlineKeyboardArrowDown />
                                                                    ) : pdata.questionType === "Email" ? (
                                                                        <MdOutlineEmail />
                                                                    ) : pdata.questionType === "Short Text" ? (
                                                                        <MdOutlineShortText />
                                                                    ) : (
                                                                        <BsCheck2 />
                                                                    )}
                                                                </div>
                                                                <div>{selectedCardIndex + 1}</div>
                                                            </div>
                                                            <div style={{ marginLeft: "5px", fontSize: "13px", display: "flex", alignItems: "center" }}>{pdata.content.length > 23 ? pdata.content.slice(0, 20) + "..." : pdata.content}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <div style={{ width: "30px" }} className={styles["dropdown-container"]}>

                                                        <select
                                                            id="left-dropdown"
                                                            className={styles['dropdown-padding']}
                                                            value={selectedLeftOption}
                                                            onChange={handleLeftSelectChange}
                                                            style={{ width: "70px", border: "1px solid #e3e3e3;", borderRadius: "10px" }}

                                                        >
                                                            <option value="is">is</option>

                                                        </select>
                                                    </div>

                                                    <div className={styles["dropdown-container"]}>
                                                        {/* <label htmlFor="right-dropdown">Dropdown 3:</label> */}


                                                        <CustomSelect
                                                            onChange={handleRightSelectChange}
                                                            width="191px" // Customize width as needed
                                                            options={[
                                                                ...pdata.options.map(option => ({ value: option, label: option })),
                                                                { value: 'For all options', label: 'For all options' }
                                                            ]}
                                                            value={selectedRightOption}
                                                            placeholder="Select an option"
                                                            borderColor="#yourBorderColor"
                                                            optionSelectedColor="#yourOptionSelectedColor"
                                                            optionHoverColor="#yourOptionHoverColor"
                                                            showError={false} // Set to true if you want to show error messages
                                                        />




                                                    </div>
                                                </div>


                                                <div className={styles["dropdown-container"]}>
                                                    <label style={{ marginRight: "5px" }} htmlFor="bottom-dropdown">then go to:</label>

                                                    <CustomSelect
                                                        onChange={handleBottomSelectChange} // Pass your onChange handler function
                                                        width="60%" // Customize the width if needed
                                                        options={filteredCardList.map((option) => ({
                                                            value: option.question_id,
                                                            label: option.content,
                                                        }))} // Map your options to match the format expected by react-select
                                                        placeholder="Select an option" // Set the placeholder text
                                                        value={selectedBottomOption} // Pass the selected value
                                                        showError={false} // Set to true if you want error handling
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className={styles["dropdown-container"]}>
                      <label htmlFor="card-dropdown">All other cases goes to:   </label>
                      <select
                        id="card-dropdown"
                        value={selectedCard}
                        onChange={handleSelectChange}
                      >
                        <option value="">Select a card</option>
                       
                      </select>
                    </div> */}

                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {(pdata.questionType == "Multiple Choice" || pdata.questionType == "Dropdown") && (
                                                    <div style={{ marginLeft: "10px" }}>
                                                        <button
                                                            onClick={toggleSaveRule}
                                                            className={`${styles['Addrule-button-sidebar']}${(selectedRightOption === "" || selectedBottomOption === "") ? ' disabled' : ''}`}
                                                            disabled={(selectedRightOption === "" || selectedBottomOption === "")}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                )}

                                                <span onClick={toggleDeleteRule} className={styles["delete-this-rule"]}>Delete this rule</span>
                                            </div>

                                        </div>
                                    )}






                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={openConfirm}
                                        onClose={handleClose}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >
                                        <Fade in={openConfirm}>
                                            <Box sx={styleConfirmModal}>
                                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                                    Alert
                                                </Typography>
                                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                    Are you sure you want to save these changes
                                                </Typography>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                                    <Button variant="contained" color="primary" onClick={SendRuleToServer}>
                                                        Save
                                                    </Button>
                                                    <Button variant="outlined" color="secondary" onClick={handleModalClose} style={{ marginLeft: '1rem' }}>
                                                        Exit
                                                    </Button>
                                                </div>
                                            </Box>
                                        </Fade>
                                    </Modal>

                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={deleteQuestion}
                                        onClose={handleDeleteQuestion}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >
                                        <Fade in={deleteQuestion}>
                                            <Box sx={styleConfirmModal}>
                                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                                    Alert
                                                </Typography>
                                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                    Are you sure you want to delete this question
                                                </Typography>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>

                                                    <Button variant="contained" color="error" onClick={handleDeleteOptionConfirm} startIcon={<DeleteIcon />}>
                                                        Delete
                                                    </Button>
                                                    {/* <Button variant="contained" color="error" onClick={handleDeleteOptionConfirm}>
                              Delete
                            </Button> */}
                                                    <Button variant="outlined" color="secondary" onClick={handleDeleteQuestion} style={{ marginLeft: '1rem' }}>
                                                        Exit
                                                    </Button>
                                                </div>
                                            </Box>
                                        </Fade>
                                    </Modal>

                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={openError}
                                        onClose={handleModalErrorClose}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >
                                        <Fade in={openError}>
                                            <Box sx={styleConfirmModal} style={{ height: "fit-content", maxWidth: '400px', margin: 'auto', background: 'white', borderRadius: '8px', padding: '20px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: "-10px" }}>
                                                    <span style={{ color: 'red', fontSize: '40px' }}>
                                                        <FiAlertTriangle />
                                                    </span>
                                                    <Typography id="transition-modal-title" variant="h6" component="h2" style={{ textAlign: 'center', marginTop: '10px' }}>
                                                        Oh Snap
                                                    </Typography>
                                                </div>
                                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                    Please review the questionnaire as it has not been filled properly.
                                                </Typography>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        style={{ marginTop: '20px', textTransform: 'none' }}
                                                        onClick={handleModalErrorClose}
                                                    >
                                                        Close
                                                    </Button>
                                                </div>
                                            </Box>
                                        </Fade>
                                    </Modal>

                                    <Modal
                                        aria-labelledby="transition-modal-title"
                                        aria-describedby="transition-modal-description"
                                        open={RemoveLogicModal}
                                        onClose={handleCloseRemoveModal}
                                        closeAfterTransition
                                        slots={{ backdrop: Backdrop }}
                                        slotProps={{
                                            backdrop: {
                                                timeout: 500,
                                            },
                                        }}
                                    >
                                        <Fade in={RemoveLogicModal}>
                                            <Box sx={styleConfirmModal}>
                                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                                    Alert
                                                </Typography>
                                                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                                    Are you sure you to remove all the logics?
                                                </Typography>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                                    <Button variant="contained" color="error" onClick={ConfirmRemoveRule}>
                                                        Remove
                                                    </Button>
                                                    <Button variant="outlined" color="secondary" onClick={handleCloseRemoveModal} style={{ marginLeft: '1rem' }}>
                                                        Exit
                                                    </Button>
                                                </div>
                                            </Box>
                                        </Fade>
                                    </Modal>




                                    {/* {(pdata.questionType !== "Statement") && ( */}
                                    <div className={styles["button-container-rule"]}>
                                        <div>
                                            <button
                                                onClick={toggleCard}
                                                className={`${styles['Addrule-button-sidebar']}${isExpanded ? ' disabled' : ''}`}
                                                disabled={isExpanded}
                                            >

                                                <BsPlusLg /> Add rule
                                            </button>
                                        </div>
                                        {(pdata.logic && pdata.logic.length !== 0) && (
                                            <div onClick={toggleRemoveRule} className={styles['Clear-button-sidebar']}>
                                                Remove all Logics
                                            </div>
                                        )}


                                    </div>

                                    {/* )} */}


                                </div>
                            </div>
                        )}
                    </div>
                )}

                <div className={styles["button-container-sidebar"]}>
                    <button onClick={openConfirmModal} className={styles['Save-button-sidebar']} >Save Questionnaire</button>
                    {/* <button onClick={toggleDeleteRule} className={styles['Cancel-button-sidebar']} >Cancel</button> */}

                </div>


            </div>
        </div>
    ));


    return (
        <>

            <MenuSideBar
                page={"Dashboard"}
                handleClick={handleClick}
                ShowIndicatior={true}
            />
            <div className='main-mychat'>
                <Navbar />


                <div style={{ display: 'flex' }}>

                    <div className={styles['main-mychat']}>

                        {/* Alerts */}
                        <BotFlowAlerts showAlert={showAlert} showAlertDelete={showAlertDelete} showAlertDuplicate={showAlertDuplicate} handleCloseAlert={handleCloseAlert} handleCloseAlertDelete={handleCloseAlertDelete} handleCloseDuplicate={handleCloseDuplicate} />
                        {/* Alerts */}

                        {/* Question SideBar */}
                        <BotFlowQuestionSidebar
                            setIsExpanded={setIsExpanded}
                            setSelectedCard={setSelectedCard}
                            setrecordDelete={setrecordDelete}
                            selectedCardIndex={selectedCardIndex}
                            setSelectedCardIndex={setSelectedCardIndex}
                            setFlowChart={setFlowChart}
                            hasNonEmptyContentIndex={hasNonEmptyContentIndex}
                            errorConditionMultipleIndex={errorConditionMultipleIndex}
                            errorConditionDropDownIndex={errorConditionDropDownIndex}
                            generateUniqueLogicId={generateUniqueLogicId}
                            setCardList={setCardList}
                            setshowAlertDuplicate={setshowAlertDuplicate}
                            handleDeleteOption={handleDeleteOption}
                            cardList={cardList}
                        />
                        {/* Question SideBar */}


                        <div style={{ display: "flex", width: "100%" }}>
                            {cardList.length === 0 && (
                                <div className={styles['main-container']}>

                                    <div className={styles['main-content']}>
                                        <h1>Welcome to Bot Flow</h1>
                                        <p style={{color:"#8b8b8b"}}>Create your first questionnaire for your bot and set up questions to make your flow!</p>
                                        {/* Add form or other components for creating questionnaire */}
                                    </div>
                                    <div className={styles['bot-image-container']}>
                                        <img src={botflowImage} alt='botflowImage' className={styles['bot-image']} />
                                    </div>
                                </div>
                            )}
                            {cardList.length > 0 && (
                            
                                <>
                            {!FlowChart && (

                                <BotFlowQuestionContent
                                    selectedCardIndex={selectedCardIndex}
                                    cardList={cardList}
                                    setCardList={setCardList}
                                    handleBottomSelectChange2={handleBottomSelectChange2}
                                    handleOpen={handleOpen}
                                    open={open}
                                    handleClose={handleClose}
                                    choices={choices}
                                    setChoices={setChoices}
                                    handleSave={handleSave}
                                />


                            )}
                            {/* Question content */}


                            {/* FlowChart Start */}
                            {(FlowChart) && (

                                <div style={{ width: "100%", height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                                    <p style={{ fontSize: "20px", fontWeight: "500" }} >Logic Diagram</p>
                                    <Flow cardList={cardList} />
                                </div>

                            )}
                            {/* FlowChart Ends */}


                            {/* Logic Sidebar Right */}
                            {(selectedCard !== '' && !FlowChart) && (
                                <div>
                                    {renderedList}
                                </div>
                            )}
                           
                            </>
                            )}



                        </div>


                    </div>





                </div>
            </div>



        </>

    );
}

export default BotFlow;