import axios from 'axios';

const API_BASE_URL = 'http://5.161.184.34:4200';

export const createUser = async (userData) => {
    const url = `${API_BASE_URL}/api/createUser`;

    try {
        const response = await axios.post(url, userData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(`Error: ${error.message}`);
    }
};

export const addUser = async (userData) => {
    const url = `${API_BASE_URL}/api/addUser`;

    try {
        const response = await axios.post(url, userData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(`Error: ${error.message}`);
    }
};

export const deleteUser = async (userData) => {
    const url = `${API_BASE_URL}/api/deleteUser`;

    console.log("userData in",userData)

    try {
        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: userData, 
        });

        return response.data;
    } catch (error) {
        throw new Error(`Error: ${error.message}`);
    }
};

export const fetchDisconnectedUsers = async (widgetID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/users/disconnectedUsers`, {
            params: { widgetID },
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching disconnected user:', error.message);
        throw error; // Rethrow the error to handle it in the component if needed
    }
};

export const createSession = async (username, attributes) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/session`, { username }, {
            headers: { 'Content-Type': 'application/json' },
        });

        return response.data;
    } catch (error) {
        console.error('Error creating session:', error);
        throw error;
    }
};

export const fetchBotMessages = async (widgetID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/dataarray`, {
            params: {
                widgetID: widgetID,
            }
        });
        return response.data[0].data;
    } catch (error) {
        throw new Error('Error fetching bot messages: ' + error.response.data);
    }
};

export const fetchIsActiveChat = async (admin, sessionID) => {
    console.log("user sessionsID in API before", sessionID);
    try {
        console.log("user sessionsID in API", sessionID);
        const response = await axios.get(`${API_BASE_URL}/changeIsActiveChat`, {
            params: {
                admin: admin,
                sessionID: sessionID
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching bot messages:', error.message);
        throw new Error('Error fetching bot messages: ' + error.response?.data);
    }
};


export const updateAdminSchema = async () => {
    try {
        await axios.post(`${API_BASE_URL}/updateAdmin`, null, {
            headers: { 'Content-Type': 'application/json' },
        });
        console.log('AdminSchema updated successfully');
    } catch (error) {
        console.error('Error updating AdminSchema:', error);
        // Handle the error or rethrow it based on your application's needs
        throw error;
    }
};

export const fetchOrganizationData = async (widgetID) => {
    console.log("Widget ID in api", widgetID )
    try {
        const response = await axios.get(`${API_BASE_URL}/api/getOrganizationData`, {
            params: {
                widgetID: widgetID,
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching Organization Data: ' + error.response.data);
    }
};

export const fetchAdminList = async (widgetID) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getAdminsList`, {
            params: {
                widgetID: widgetID,
            }
        });
        return response.data
    } catch (error) {
        throw new Error('Error fetching Admin List: ' + error.response.data);
    }
};



export const listAudioFiles = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/listAudioFiles`, {
        });
        return response.data;
    } catch (error) {
        throw new Error('Error fetching Audio Data: ' + (error.response ? error.response.data : error.message));
    }
};

