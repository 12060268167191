import React, { useState, useEffect, useCallback, useMemo } from 'react';
import socket from '../../socketjs.js';
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./main.module.css";
import Conversation from '../../Pages/Conversation/Conversation.js';
import MenuSideBar from '../../Components/MenuSidebar/MenuSideBar.js';
import Sidebar from "../../Components/Sidebar/Sidebar.js";
import { useAuth } from "../../Service/AuthContext.js";
import Navbar from '../../Components/Navbar/Navbar.js';
import { useConnectedUsersContext } from '../../Service/ConnectedUsersContext.js';
import { useLocation } from 'react-router-dom';
import { useSound } from '../../Service/SoundContext.js';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useUser } from '../../Service/SelectedUserContext.js';
import ChatsSubMenubar from '../../Components/ChatsSubMenubar/ChatsSubMenubar.js';


const ActiveChat_PAGE = 'ActiveChat';
const HISTORY_PAGE = 'history';
const VISITOR_PAGE = 'visitor';

const Main = (props) => {

  const {
    userName,
    CurrentusersocketID,
    CurrentuserAuth,
    CurrentusersessionID,
    page,
    pageSub
  } = props;

  const [mainSelectedUser, setSelectedUserMain] = useState({});
  const [userSelected, setUserSelected] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredUsersOnline, setFilteredUsersOnline] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [fromMessage, setFromMessage] = useState(false);
  const { connectedUsers,disconnectedUsers } = useConnectedUsersContext();
  const { playSound } = useSound();
  const { widgetID, userAttributes } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const SelectedActiveChatuser = location.state?.userToActiveChat;
  const params = useParams();
  console.log("SelectedActiveChatuser", SelectedActiveChatuser)

  const handleClick = async () => {
    console.log("Testing")
  };




  useEffect(() => {
    if (page === ActiveChat_PAGE) {
      setFilteredUsers([]);
      setIsFiltered(false);
    }
  }, [page]);

  const handleSearch = useCallback((searchValue) => {
    setIsFiltered(true);
    const lowercaseSearch = searchValue.toLowerCase();
    if (lowercaseSearch === '') {
      setIsFiltered(false);
    }

    let filteredUsersList = [];

    if (page === HISTORY_PAGE || page === VISITOR_PAGE) {
      filteredUsersList = disconnectedUsers.filter(user =>
        user.username.toLowerCase().includes(lowercaseSearch)
      );
    } else if (page === ActiveChat_PAGE) {
      filteredUsersList = connectedUsers.filter(user =>
        user.username.toLowerCase().includes(lowercaseSearch)
      );
      setFilteredUsersOnline(filteredUsersList);
    }

    setFilteredUsers(filteredUsersList);
  }, [page, connectedUsers, disconnectedUsers]);

  const getSelectedUser = useCallback((user) => {

    setSelectedUserMain((prevUser) => {
      // Use the previous state to update the state based on its current value
      if (prevUser !== user || prevUser === user) {
        setUserSelected(true);

        
        const pathname = window.location.pathname.toLowerCase();

        // Initialize the lowercasePage variable
        let lowercasePage = '';
        
        // Check if the pathname includes "visitor", "history", or "activechat"
        if (pathname.includes('visitor')) {
            lowercasePage = 'visitor';
        } else if (pathname.includes('history')) {
            lowercasePage = 'history';
        } else if (pathname.includes('activechat')) {
            lowercasePage = 'activechat';
        }
        
        
        
    
        if (`/${lowercasePage}/${user.sessionID}` !== window.location.pathname && lowercasePage !== "history") {
          navigate(`/${lowercasePage}/${user.sessionID}`);
        } else if (`/${lowercasePage}/${user.sessionID}` !== window.location.pathname && lowercasePage === "history") {
          navigate(`/${lowercasePage}/${params.chatType}/${user.sessionID}`);
        }

        console.log("lowercasePage after.....",lowercasePage)
        return user;
      }
      return prevUser;
    });
  }, [page, userSelected,params.chatType]);


  useEffect(() => {
    const handleDisconnectedUsers = (data) => {
        console.log("Recent Disconnected User Session ID ===>", data.RecentDisconnectedUser);
        if (mainSelectedUser && mainSelectedUser.sessionID === data.RecentDisconnectedUser) {
            setSelectedUserMain({});
            setUserSelected(false)
        }
    };

    socket.on('disconnected-users', handleDisconnectedUsers);

    return () => {
        socket.off('disconnected-users', handleDisconnectedUsers);
    };
}, [mainSelectedUser, socket]);



  const renderMainContent = useMemo(() => {
    if (userSelected) {
      return (
        <>
          <div className={styles['main-content']}>
            <Conversation
              selectedUser={mainSelectedUser}
              connectedUsers={connectedUsers}
              username={userName}
              CurrentusersocketID={CurrentusersocketID}
              CurrentusersessionID={CurrentusersessionID}
              CurrentuserAuth={CurrentuserAuth}
              page={page}
              widgetID={widgetID}
            />
          </div>
        </>
      );
    } else {
      console.log("params====>",params)
      const sessionID_from_url = params["sessionID"];
      let matchingUser

      console.log("disconnectedUsers=====> ", disconnectedUsers);
      if (window.location.pathname.includes("history")) {
        matchingUser = disconnectedUsers.find(user => user.sessionID === sessionID_from_url && user.isConversation === true);
      }
      if (window.location.pathname.includes("visitor")) {
        matchingUser = disconnectedUsers.find(user => user.sessionID === sessionID_from_url && !user.hasOwnProperty('isConversation'));
      }
      if (window.location.pathname.includes("activechat")) {
        matchingUser = connectedUsers.find(user => user.sessionID === sessionID_from_url && user.isActiveChat === true);
      }

      console.log("matchingUser=====> ", matchingUser);
      if (matchingUser) {
        getSelectedUser(matchingUser);
      }
    }


    // }, [page, connectedUsers, selectedUser, userSelected, userName]);
  }, [connectedUsers, mainSelectedUser, userSelected, disconnectedUsers]);

  // useeffectt()==>{

  //   if mainSelectedUser not include in connectedUsers then {

  //   }

  // },[connectedUsers]);

  return (
    <>

      <MenuSideBar
        page={"Dashboard"}
        handleClick={handleClick}
        ShowIndicatior={true}
      />
      <div className='main-mychat'>
        <Navbar />
        <div style={{ display: 'flex' }}>



          {page !== "visitor" && (
            <ChatsSubMenubar page={page}/>
          )}



          <Sidebar
            page={page}
            handleSearch={handleSearch}
            getSelectedUser={getSelectedUser}
            isFiltered={isFiltered}
            filteredUsers={filteredUsers}
            filteredUsersOnline={filteredUsersOnline}
            connectedUsers={connectedUsers}
            disconnectedUserList={disconnectedUsers}
            SelectedActiveChatuser={SelectedActiveChatuser}
            pageSub={pageSub}
          />


          {!userSelected && (
            <div className={styles['main-content']}>
              <div className={styles["no-render-message-container"]} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <div className={styles["no-render-message"]} style={{ textAlign: 'center', padding: '20px', borderRadius: '4px' }}>
                  <p style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>Click chat to start messaging</p>
                  <p style={{ fontSize: '14px', color: '#666' }}>Get connected and start a conversation with someone</p>
                </div>
              </div>
            </div>
          )}


          {(params && params.sessionID) && (
            renderMainContent
          )}



        </div>
      </div>
    </>
  );
};

export default Main;
