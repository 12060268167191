import { io } from "socket.io-client";
const URL = "http://5.161.184.34:4200/";

const socket = io(URL, { autoConnect: false });

socket.onAny((event, ...args) => {
  console.log(event, args);

});
export default socket;
