import { HiCode, HiOutlineMailOpen } from "react-icons/hi";
import Button from '@mui/material/Button';
import { IoIosArrowRoundBack } from "react-icons/io";
import styles from "../forgotPassword.module.css"
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export default function ResetCodeComponent({ email, handleCodeChange, handleCodeSubmit, code, inputRefs, loading, handleKeyPress }) {
    const navigate = useNavigate()

    const handleResendCode = async () => {
        try {
            await Auth.forgotPassword(email);
        } catch (error) {
            if (error.code === 'UserNotFoundException') {
                console.error('User not found. Please check the username and try again.');
            } else {
                console.error('Error initiating password reset:', error.message);
            }
        }
    }

    return (
        <div className={styles.email_container} style={{ gap: "10px" }}>
            <div className={styles.top_section}>
                <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <HiOutlineMailOpen style={{ width: "20px", height: "20px" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p}>Password reset</p>
                    <p style={{ color: "#8E8EA0", fontSize: "15px", textAlign: "center" }}>We sent a code to <span style={{ color: "black", fontWeight: "bold" }}>{email}</span>.</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container} style={{ flexDirection: "row", gap: "4px" }}>
                    {
                        Object.keys(code).map((fieldName, index) => (
                            <input
                                style={{ flex: "1", width: "30px", textAlign: "center", height: "50px", fontSize: "30px" }}
                                className={styles.input_field}
                                key={fieldName}
                                name={fieldName}
                                type="text"
                                maxLength={1}
                                value={code[fieldName]}
                                onChange={(e) => handleCodeChange(e.target.name, e.target.value)}
                                onKeyUp={(e) => handleKeyPress(e.target.name, e)}
                                ref={(ref) => (inputRefs.current[index + 1] = ref)}
                            />
                        ))
                    }
                    {/* <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit1" value={code.digit1} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} />
                    <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit2" value={code.digit2} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} />
                    <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit3" value={code.digit3} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} />
                    <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit4" value={code.digit4} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} />
                    <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit5" value={code.digit5} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} />
                    <input style={{flex:"1", width:"30px", textAlign:"center", height:"50px", fontSize:"30px"}} className={styles.input_field}  maxLength={1} type="text" name="digit6" value={code.digit6} onChange={(e) => handleCodeChange(e.target.name, e.target.value)} /> */
                    }

                </div>
                <div>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={handleCodeSubmit} variant="contained" fullWidth>Submit</Button>
                </div>
                <div style={{ textAlign: "center", color: "#8E8EA0" }}>
                    Didn't receive the email? 
                    <span style={{ color: 'blue', textDecoration: 'underline' }} onClick={handleResendCode}>
                            Click resend
                    </span>

                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}