import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./BotFlowQuestionSidebar.module.css"
import { useState } from "react";
import { MdDelete, MdOutlineKeyboardArrowDown, MdOutlineEmail, MdOutlineShortText } from "react-icons/md";
import { BsPlusLg, BsCheck2, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { TiInputChecked } from "react-icons/ti";
import { BiDuplicate } from "react-icons/bi";
import { Diversity2Rounded } from "@mui/icons-material";


export default function BotFlowQuestionSidebar({ setIsExpanded, setSelectedCard, setrecordDelete,
    selectedCardIndex, setSelectedCardIndex, setFlowChart, hasNonEmptyContentIndex, errorConditionMultipleIndex,
    errorConditionDropDownIndex, generateUniqueLogicId, setCardList, setshowAlertDuplicate, handleDeleteOption, cardList }) {

    const [showOptions, setShowOptions] = useState(false);
    const [activeFlow, setActiveFlow] = useState(true);

    const options = [
        'Multiple Choice',
        'Short Text',
        'Statement',
        'Dropdown',
        'Email',
    ];

    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('index', index);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    const handleDrop = (e, toIndex) => {
        e.preventDefault();
        const fromIndex = e.dataTransfer.getData('index');

        const updatedCardList = [...cardList];
        const [draggedCard] = updatedCardList.splice(fromIndex, 1);
        updatedCardList.splice(toIndex, 0, draggedCard);

        setCardList(updatedCardList);
    };

    const handlePlusIconClick = () => {
        setShowOptions(!showOptions);
    };
    const handleActiveQuestions = () => {
        setActiveFlow(true);
        setFlowChart(false)
    };
    const handleFlowActive = () => {
        setFlowChart(true)
        setActiveFlow(false);
        setShowOptions(false);
    };

    function generateUniqueQuestionId(questionType) {
        // Remove spaces from the questionType and concatenate it with the current timestamp
        const formattedQuestionType = questionType.replace(/\s/g, ''); // Remove spaces
        return `${formattedQuestionType}_${Date.now()}`;
    }

    const handleDuplicateOption = (index) => {
        const originalQuestion = cardList[index];

        // Generate new unique identifiers for the duplicated question and its logic
        const newQuestionId = generateUniqueQuestionId(originalQuestion.questionType);
        const newLogicId = generateUniqueLogicId();

        // Create a deep copy of the original question
        const duplicatedQuestion = JSON.parse(JSON.stringify(originalQuestion)); // Deep copy

        // Assign a new question_id to the duplicated question
        duplicatedQuestion.question_id = newQuestionId;

        // If the original question has a logic array, duplicate the logic with updated IDs
        if (originalQuestion.logic) {
            duplicatedQuestion.logic = originalQuestion.logic.map((originalLogic) => ({
                ...originalLogic,
                logicId: newLogicId,
                question_id: newQuestionId, // Update the logic's question_id
            }));
        }

        // Create a copy of the card list and insert the duplicated question
        const updatedCardList = [...cardList];
        updatedCardList.splice(index + 1, 0, duplicatedQuestion);

        setCardList(updatedCardList);
        setshowAlertDuplicate(true)
    };

    const handleOptionSelect = (option) => {


        let newCard;
        if (option === "Multiple Choice") {
            newCard = {
                question_id: generateUniqueId('MultipleChoice'), // Generate a unique ID
                questionType: 'Multiple Choice',
                content: '',
                options: ['', '', ''],
                istrue: "true",
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        } else if (option === "Statement") {
            newCard = {
                question_id: generateUniqueId('Statement'), // Generate a unique ID
                questionType: 'Statement',
                content: '',
                istrue: "true",
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        } else if (option === "Short Text") {
            newCard = {
                question_id: generateUniqueId('ShortText'), // Generate a unique ID
                questionType: 'Short Text',
                content: '',
                istrue: "true",
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        }
        else if (option === "Email") {
            newCard = {
                question_id: generateUniqueId('Email'), // Generate a unique ID
                questionType: 'Email',
                content: '',
                istrue: "true",
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        } else if (option === "Dropdown") {
            newCard = {
                question_id: generateUniqueId("Dropdown"), // Generate a unique ID
                questionType: option,
                content: '',
                istrue: "true",
                options: [],
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        } else {
            newCard = {
                question_id: generateUniqueId(), // Generate a unique ID
                questionType: option,
                content: '',
                istrue: "true",
                from_Usergroup: "bot",
                from_Username: "bot",
            };
        }

        // Update cardList with the new card
        setCardList([...cardList, newCard]);

        setShowOptions(false);
    };
    const generateUniqueId = (questionType) => {
        return `${questionType}_${Date.now().toString(36) + Math.random().toString(36).substr(2)}`;
    };

    return (
        <div className={styles.AI_sidebar}>
            <div className={styles.question_container}>
                <h4 className={styles.question_title}>Question</h4>
                {activeFlow && (
                    <button onClick={handlePlusIconClick} className={styles.plus_icon}>
                        +
                    </button>
                )}

            </div>

            <div className={styles.toggle_btn_visitor_leftsidebar}>
                <span
                    className={activeFlow ? styles['active-visitor'] : styles['non-active-visitor']}
                    onClick={handleActiveQuestions}
                >
                    Questions
                </span>
                <span
                    className={!activeFlow ? styles['active-visitor'] : styles['non-active-visitor']}
                    onClick={handleFlowActive}
                >
                    Flow
                </span>
            </div>

            <div className={styles.selected_options}>
                {cardList.length === 0 ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontSize: '13px',
                            alignItems: 'center',
                            height: '63vh', // Adjust this to control the height of the container
                        }}
                    >
                        Create your first questionnaire
                    </div>
                ) : (
                    cardList.map((card, index) => (
                        <div
                            onClick={() => {
                                setSelectedCardIndex(index);
                                setSelectedCard([card]);
                                setIsExpanded(false);
                                setActiveFlow(true);
                                setFlowChart(false);
                                setrecordDelete(false);
                                setShowOptions(false);
                            }}
                            key={index}
                            className={`${styles.card_questions} ${selectedCardIndex === index ? styles.selected_card : ''
                                }`}
                            style={{
                                backgroundColor:
                                    index === hasNonEmptyContentIndex ||
                                        index === errorConditionMultipleIndex ||
                                        index === errorConditionDropDownIndex
                                        ? 'rgb(244, 199, 199)'
                                        : '',
                                border:
                                    index === hasNonEmptyContentIndex ||
                                        index === errorConditionMultipleIndex ||
                                        index === errorConditionDropDownIndex
                                        ? '1px solid #ff000054'
                                        : '',
                            }}
                            draggable="true"
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e, index)}
                        >
                            <div
                                className={styles.card_index}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                    backgroundColor:
                                        card.questionType === 'Multiple Choice'
                                            ? 'rgb(214, 92, 153)'
                                            : card.questionType === 'Statement'
                                                ? 'rgb(55, 156, 251)'
                                                : card.questionType === 'Short Text'
                                                    ? 'rgb(251, 161, 55)'
                                                    : card.questionType === 'Dropdown'
                                                        ? 'cadetblue'
                                                        : card.questionType === 'Email'
                                                            ? 'rgb(155, 206, 253)'
                                                            : 'inherit',
                                    color:
                                        card.questionType === 'Multiple Choice'
                                            ? 'black'
                                            : 'inherit',
                                }}
                            >
                                {card.questionType === 'Multiple Choice' ? (
                                    <TiInputChecked />
                                ) : card.questionType === 'Dropdown' ? (
                                    <MdOutlineKeyboardArrowDown />
                                ) : card.questionType === 'Email' ? (
                                    <MdOutlineEmail />
                                ) : card.questionType === 'Short Text' ? (
                                    <MdOutlineShortText />
                                ) : (
                                    <BsCheck2 />
                                )}
                                {index + 1}
                            </div>

                            {card.content ? (
                                card.content.length > 25
                                    ? `${card.content.slice(0, 25)}...`
                                    : card.content
                            ) : (
                                <div>...</div>
                            )}

                            <div className={styles.card_content}>
                                <BiDuplicate onClick={() => handleDuplicateOption(index)} />
                                <MdDelete onClick={() => handleDeleteOption(index)} />
                            </div>
                        </div>
                    ))
                )}
            </div>


            {showOptions && (
                <div className={styles.options_dropdown}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={styles.option}
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}