import styles from "./installation.module.css"
import { MdError } from "react-icons/md";
import { IoBrushSharp } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaExternalLinkAlt } from "react-icons/fa";
import TextField from '@mui/material/TextField';
import { MdInstallDesktop } from "react-icons/md";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from "react";
import { useAuth } from "../../../../Service/AuthContext.js";
import { fetchOrganizationData } from "../../../../Service/API.js";






export const Installation = ({ organizationData }) => {
    const [open, setOpen] = useState(false);
    const [installionScript, setInstallionScript] = useState("");

    const { widgetID } = useAuth();
    console.log("Widget ID ; ", widgetID)


    useEffect(() => {
        const fetchData = async () => {
            console.log("widgetID in setting=====> : ", widgetID);
            try {
                const orgData = await fetchOrganizationData(widgetID);
                setInstallionScript(orgData[0].updatedScript);
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };
    
        if (organizationData.updatedScript) {
            setInstallionScript(organizationData.updatedScript);
        } else {
            fetchData();
        }
    }, [organizationData.updatedScript, widgetID]);


    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const copyToClipBoard = (installionScript) => {
        navigator.clipboard.writeText(installionScript);
        handleClick()
    }

    return (
        <div className={styles.installation_container}>
            <div className={styles.error_container}>
                <MdError className={styles.error_icon} />
                <p style={{ margin: "0px" }}>The chat code is not installed properly</p>
            </div>
            <p style={{ marginTop: "1rem" }}>It looks like you haven't installed the chat code yet.
                Choose from one of the installation guides below:
            </p>
            <div className={styles.installation_guide_container}>
                {/* left side */}
                <div className={styles.left_side}>
                    <div className={`${styles.installation_button} `} id="appearance">
                        <MdInstallDesktop className={`${styles.icon} `} />
                        {/* <img src={install} className={styles.icon} /> */}

                        <span className={styles.navigation_item_text}>Manual install</span>
                    </div>
                </div>
                {/* right side */}
                <div className={styles.right_side}>
                    <div className={styles.top_section}>
                        <p style={{ margin: "0px" }}>Paste this code snippet just before the &lt;/body&gt; tag.</p>
                        {/* <textarea>paste this code before your website's body tag</textarea> */}
                        <div style={{ position: 'relative' }}>
                            <label style={{ position: 'absolute', top: 0, left: 0, fontSize: 14, color: '#666', pointerEvents: 'none' }}>code snippet</label>
                            <TextField
                                multiline
                                rows={4} // You can adjust rows as needed
                                variant="outlined"
                                style={{ width: '100%', marginTop: 20, backgroundColor: "#F6F6F6" }} // Adjust styles as needed
                                InputProps={{
                                    style: { color: "#8E8EA0" } // Set color here
                                }}
                                value={installionScript.replace("\n", "").trim()}
                                aria-readonly
                            />
                        </div>
                        <div className={styles.copy_btn_container} onClick={() => copyToClipBoard(installionScript)}>

                            <MdOutlineContentCopy className={styles.copy_icon} />
                            <button className={styles.copy_btn} >Copy to clipboard</button>
                        </div>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert
                                onClose={handleClose}
                                severity="info"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                Successfully Copied the code!
                            </Alert>
                        </Snackbar>
                    </div>
                    <div className={styles.bottom_section}>
                        <span style={{ color: "#8E8EA0" }}>Need more help?</span>
                        <span className={styles.help_center}>visit our help Center <FaExternalLinkAlt /></span>
                        <span style={{ color: "#2563EB" }}>Chat with us</span>
                    </div>
                </div>
            </div>
        </div>
    )
}