import React from 'react';

const PDFEmbed = ({ pdfUrl }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <embed
        src={pdfUrl}
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PDFEmbed;
