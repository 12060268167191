import styles from "./settingsSideMenu.module.css"
import { IoBrushSharp } from "react-icons/io5";
import { GrInstallOption } from "react-icons/gr";
import { MdAccountCircle } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { MdGroup } from "react-icons/md";

export const SettingsSideMenu = ({page, handleNavigation}) => {
    return (
        <div className={styles.navigation_menu}>
            <h1 className={styles.title}>Settings</h1>
            <div className={styles.navigtion_section}>
                {/* <p className={styles.p_text}>CHANNELS</p> */}
                <div className={styles.navigation_items_container}>
                    {/* <div className={`${styles.navigation_item} ${page === 'appearance' && styles.active_class}`} id="appearance" onClick={handleNavigation}>
                    <IoBrushSharp className={`${styles.icon} ${page === 'appearance' && styles.active_icon_class}`} />
                    <span className={styles.navigation_item_text}>Appearance</span>
                </div> */}
                    <div className={`${styles.navigation_item} ${page === 'account' && styles.active_class}`} id="account" onClick={handleNavigation}>
                        <MdAccountCircle className={`${styles.icon} ${page === 'account' && styles.active_icon_class}`} />
                        <span className={styles.navigation_item_text}>Account</span>
                    </div>
                    <div className={`${styles.navigation_item} ${page === 'installation' && styles.active_class}`} id="installation" onClick={handleNavigation}>
                        <GrInstallOption className={`${styles.icon} ${page === 'installation' && styles.active_icon_class}`} />
                        <span className={styles.navigation_item_text}>Installation</span>
                    </div>
                    <div className={`${styles.navigation_item} ${page === 'notification' && styles.active_class}`} id="notification" onClick={handleNavigation}>
                        <IoMdNotifications className={`${styles.icon} ${page === 'notification' && styles.active_icon_class}`} />
                        <span className={styles.navigation_item_text}>Notifications</span>
                    </div>
                    <div className={`${styles.navigation_item} ${page === 'operators' && styles.active_class}`} id="operators" onClick={handleNavigation}>
                        <MdGroup className={`${styles.icon} ${page === 'operators' && styles.active_icon_class}`} />
                        <span className={styles.navigation_item_text}>Operators</span>
                    </div>
                </div>
            </div>
        </div>
    )
}