import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { QuestionIcon } from '../icons/QuestionIcon';
import styles from "./basicTable.module.css"
import { AiOutlineWarning } from "react-icons/ai";
import CustomSelect from '../../../../../Components/CustomSelect/CustomSelect';

import { FaVolumeUp } from "react-icons/fa";




// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


export default function BasicTable({ audioFilesData }) {
    const options = audioFilesData
    const [newMessageVolumeIcon, setNewMessageVolumeIcon] = useState(false)
    const [newVisitorVolumeIcon, setNewVisitorVolumeIcon] = useState(false)
    const [newChatVolumeIcon, setNewChatVolumeIcon] = useState(false)


    const handleNewMessage = (selectedOption) => {
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewMessageVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewMessageVolumeIcon(false);
            });

            audio.play();
        }
    };

    const handleNewVisitor = (selectedOption) => {
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewVisitorVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewVisitorVolumeIcon(false);
            });

            audio.play();
        }
    };

    const handleChatRequest = (selectedOption) => {
        const selected = selectedOption ? selectedOption.value : "";
        console.log("selected", selected);

        if (selected && selectedOption.value) {
            const audio = new Audio(selected);


            setNewChatVolumeIcon(true);

            audio.addEventListener('ended', () => {
                setNewChatVolumeIcon(false);
            });

            audio.play();
        }
    };

    

    return (
        <TableContainer component={Paper} className={styles.table_container} style={{ maxHeight: "420px", overflowX: "unset" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell className={styles.table_heading}>Notification type</TableCell>
                        <TableCell className={styles.table_heading}>Email <span className={styles.question_icon_container}><QuestionIcon /></span></TableCell>
                        <TableCell className={styles.table_heading} >Web notification <span className={styles.question_icon_container}><QuestionIcon /></span></TableCell>
                        <TableCell className={styles.table_heading}>Web notification sound</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>New message</TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <div className={styles.volume_IconContainer}>
                                    {newMessageVolumeIcon && <FaVolumeUp className={newMessageVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>
                                <CustomSelect width={"300px"} onChange={handleNewMessage} options={options.messages} />
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>New Vistor Lands</TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                            <div className={styles.volume_IconContainer}>
                                    {newVisitorVolumeIcon && <FaVolumeUp className={newVisitorVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>

                                <CustomSelect onChange={handleNewVisitor} width={"300px"} options={options.messages} />
                            </div>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>New chat request</TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                            <div className={styles.volume_IconContainer}>
                                    {newChatVolumeIcon && <FaVolumeUp className={newChatVolumeIcon ? styles.fadeIn : styles.fadeOut} />}
                                </div>

                                <CustomSelect onChange={handleChatRequest} width={"300px"} options={options.Alert} />
                            </div>
                        </TableCell>
                    </TableRow>

                    {/* <TableRow>
                        <TableCell><div>Incoming visitor</div>
                            <span className={styles.fa_inactive}><AiOutlineWarning className={styles.warning_sign} />You won’t receive any notifications of this type.</span>
                        </TableCell>
                        <TableCell className={styles.table_heading}>Not available</TableCell>
                        <TableCell><input className={styles.checkbox} type="checkbox" name="" id="" /></TableCell>
                        <TableCell>
                            <div className={styles.dropdownContainer}>
                                <CustomSelect width={"300px"} options={options} />
                            </div>
                        </TableCell>
                    </TableRow> */}
                </TableBody>
            </Table>
        </TableContainer>
    );
}