// StepperComponent.jsx
import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Check } from "@mui/icons-material";
import styled from '@mui/system/styled';


const CustomIcon = ({ active, completed, icon }) => {
    const contents = completed ? <Check fontSize="inherit" /> : icon;
    return (
        <div style={{
            backgroundColor: active || completed ? "rgb(246, 126, 78)" : "#E6EEF1",
            color: active || completed ? "white" : "#083A5E",
            minHeight: "40px",
            minWidth: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            fontSize: "1rem",
            marginTop: "-7px",
            fontWeight: "bold",
        }}>
            {contents}
        </div>
    );
};

const StepperComponent = ({ activeStep, steps }) => {

    const ColorlibStepLabel = styled(StepLabel)(({ theme }) => ({
        [`& .${stepLabelClasses.label}`]: {
            color: "#5e5e5e",
            fontSize: "13px", 
        },
    }));

    const stepLabelClasses = {
        label: 'label-class',
    };


    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
                <Step key={label}>

                    <ColorlibStepLabel
                        StepIconComponent={CustomIcon}
                        classes={{
                            label: stepLabelClasses.label,
                        }}
                    >
                        {label}
                    </ColorlibStepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default StepperComponent;
