export const ActiveChatsIcon = ({ fill, width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none" style={{width:width, height:height}}>
            <g clip-path="url(#clip0_6_2289)">
                <path d="M16.8928 10.5612C16.7228 12.6437 14.9591 14.2587 12.8553 14.2375H10.6241L6.37408 17V14.2375L10.6241 11.475H12.8553C13.5141 11.4963 14.0666 11.0075 14.1516 10.3487C14.2791 8.22375 14.2791 6.09875 14.1091 3.97375C14.0666 3.37875 13.5778 2.9325 13.0041 2.89C11.5591 2.82625 10.0291 2.7625 8.49908 2.7625C6.96908 2.7625 5.43908 2.805 3.99408 2.91125C3.39908 2.95375 2.93158 3.4 2.88908 3.995C2.74033 6.12 2.71908 8.245 2.84658 10.37C2.91033 11.0287 3.48408 11.4963 4.14283 11.475H6.37408V14.2375H4.14283C3.12916 14.2508 2.14827 13.8786 1.39868 13.1961C0.649096 12.5135 0.186792 11.5717 0.105333 10.5612C-0.0434167 8.2875 -0.0221667 6.035 0.147833 3.76125C0.225674 2.81837 0.639738 1.93485 1.31455 1.27174C1.98936 0.608633 2.87998 0.210087 3.82408 0.14875C5.31158 0.0425 6.90533 0 8.49908 0C10.0928 0 11.6866 0.0425 13.1953 0.17C14.1394 0.231337 15.0301 0.629883 15.7049 1.29299C16.3797 1.9561 16.7937 2.83962 16.8716 3.7825C17.0203 6.035 17.0416 8.30875 16.8928 10.5612Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_6_2289">
                    <rect width="17" height="17" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}