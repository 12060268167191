import { useState, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag"
import { GoPrimitiveDot } from 'react-icons/go';
import { AiOutlineClockCircle } from 'react-icons/ai'
import socket from '../../../socketjs.js';
import styles from "./visitor.module.css"
import { useUser } from '../../../Service/SelectedUserContext.js';
import { useParams } from 'react-router-dom';


const Visitors = (props) => {

  const userList = props.connectedUsers;
  const { selectedUser, setSelectedUser } = useUser(props.selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    console.log("icecream", userList)
    if (userList.length !== 0) {
      setIsLoading(false);
    }
  }, [userList]);

  localStorage.setItem('HistoryUsers', JSON.stringify(userList));


  const userName_from_click = (selectedUser) => {
    selectedUser.page = 'visitor';
    setSelectedUser(selectedUser);
    console.log("userSelected props", props)
    props.selectUser(selectedUser);
  };

  useEffect(() => {
    if (userList.length > 0) {

      if (params?.hasOwnProperty("sessionID")) {
        const userSelected = userList.find(user => user.sessionID === params["sessionID"]);
        userName_from_click(userSelected);
      } else {
        console.error("Session ID not found in params.");
      }
    }
  }, [userList,params?.sessionID]); // Run the effect when userList changes


  const FilterArray = userList.filter(user => !user.hasOwnProperty('isConversation'))

  let showUsers = FilterArray
    .sort((userA, userB) => {
      const disconnectTimeA = new Date(userA.disconnectTime);
      const disconnectTimeB = new Date(userB.disconnectTime);
      return disconnectTimeB - disconnectTimeA; // Sort in descending order
    })
    .map(user => {
      const isSelected = selectedUser && selectedUser.username === user.username;
      const userStyle = isSelected ? { backgroundColor: '#26A3F6' } : { backgroundColor: '#f5f5f5' };

      // Calculate the time difference
      const disconnectTime = new Date(user.disconnectTime);
      const currentTime = new Date();

      const timeDifference = currentTime - disconnectTime;

      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let timeAgo;

      if (daysDifference >= 7) {
        const weeksDifference = Math.floor(daysDifference / 7);
        timeAgo = `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
      } else if (daysDifference > 0) {
        timeAgo = `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
      } else if (hoursDifference > 0) {
        timeAgo = `${hoursDifference}h ago`;
      } else if (minutesDifference > 0) {
        timeAgo = `${minutesDifference}m ago`;
      } else {
        timeAgo = '10s ago';
      }

      return (

        <div
          key={user._id}
          className={`${styles['main-sidebar-content-cnt']} ${isSelected ? styles.selected : styles.notSelected}`}
          onClick={() => {
            userName_from_click(user);
          }}
          ref={(ref) => {
            // Add a ref to the selected user's element
            if (isSelected) {
              ref && ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }}
        >
          <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              <GoPrimitiveDot
                className={styles['offline-icon-dot']}
                style={{ color: user.isOnline === true ? "lightgreen" : "#FFA300" }}
              />
              <span style={{ fontWeight: 500 }}>{user.username}</span>
            </div>
            <span style={{ padding: '2px 8px', borderRadius: '20px', fontSize: '12px', fontWeight: "500", color: '#40464f' }} className={styles['sidebar-top-right']}><AiOutlineClockCircle className={styles.clock} /> {timeAgo}</span>

          </div>

          <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              <ReactCountryFlag countryCode={user.country_code2} svg />
              <span className={styles['sidebar-cnt-dn-cntry']}>{user.country_name}</span>
            </div>
          </div>

        </div>

      );
    });

  return (


    <div>
      {FilterArray.length > 0 ? (
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', marginTop: "25px" }}>
            <div className={styles.loader}></div>
          </div>
        ) : (
          // Display the user list once loading is done
          <div style={{ marginBottom: "15%" }}>
            <div>{showUsers}</div>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
            alignItems: 'center',
            height: '63vh', // Adjust this to control the height of the container
          }}
        >
          No visitors
        </div>
      )}
    </div>

  );
}

export default Visitors