import { Bar } from 'react-chartjs-2';
import { BarElement } from 'chart.js';
import Chart from 'chart.js/auto';
Chart.register(BarElement);

export default function BarChart({barChartFilter,disconnectedUsers}) {
    console.log("Bar Chart Filter in barchart : ", barChartFilter)
    const disconnectedUserList = [
        {
            connectionTime: "Wednesday 14 March 2024 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2024 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2024 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2024 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2023 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 March 2023 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 March 2023 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 Jun 2023 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2023 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2022 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2022 9:15:30 AM",
            isConversation: true,
        },
        {
            connectionTime: "Wednesday 14 February 2022 9:15:30 AM",
            isConversation: true,
        },
        
        // Add more dummy data objects as needed...
    ];

    const usersFilteredByYear = disconnectedUsers.filter(user => new Date(user.connectionTime).getFullYear() === barChartFilter)
    console.log("Filtered Users : ", usersFilteredByYear)

    const userCounts = {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
    };

    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        return month;
    };

    usersFilteredByYear.forEach(user => {
        const date = new Date(user.connectionTime);
        const month = formatDate(date);
        userCounts[month]++;
    });

    // Convert the user counts object into arrays for Chart.js
    const months = Object.keys(userCounts);
    const counts = Object.values(userCounts);

    console.log("My Data haad : ", userCounts)

    // Set the chart data
    const chartData = {
        labels: months,
        datasets: [
            {
                label: 'Chats',
                data: counts,
                // #DBE7EE
                backgroundColor: "rgb(26, 115, 232)", // Adjust as needed
                borderWidth: 1,
                // borderRadius:2,
            },
        ],
    };
    return (
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            },
                        }],
                        x: {
                            grid: {
                                display: false, // Hide vertical grid lines
                            },
                        },
                        y: {
                            grid: {
                                display: false, // Hide horizontal grid lines
                            },
                            ticks: {
                                stepSize: 10, // Set the step size to 1 to display integer values
                                min: 0,
                                max: 6,
                                color: 'grey',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide the legend (square with label)

                        },
                    },
                }}
            />
    )
}