import styles from "./navbar.module.css"
import { useEffect, useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import socket from '../../socketjs';
import { BsBellFill } from "react-icons/bs";
import Avatar from '@mui/material/Avatar';
import UserOptionsDropdown from "./Navbar_Components/UserOptionsDropdown";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { renderMatches, useNavigate } from 'react-router-dom';
import { useAuth } from "../../Service/AuthContext";
import { useConnectedUsersContext } from "../../Service/ConnectedUsersContext";
import { BsSearch } from 'react-icons/bs';
import axios from 'axios';
import { useGlobalVariablesContext } from "../../Service/GlobalVariables";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineSearchOff } from "react-icons/md";

const Navbar = () => {
  const dropdownRef = useRef(null);

  const [userOption, setUserOption] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);
  const { setAuthData, setWidgetData, userAttributes, setUserAttributes } = useAuth();
  const { profilePicture } = useGlobalVariablesContext();
  const [userName, setUserName] = useState(userAttributes.name)
  const [currentprofilePicture, setCurrentprofilePicture] = useState(profilePicture)
  const isInitialRender = useRef(true);
  const isInitialPicture = useRef(true);
  const { setDisconnectedUsers, setConnectedUsers, disconnectedUsers } = useConnectedUsersContext();

  const [searchQuery, setSearchQuery] = useState('');
  console.log("search query : ", searchQuery)
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [expandedInput, setExpandedInput] = useState(false);

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value.length < 2) {
      setFilteredUsers([])
      return
    }
    // Filter users based on the search query
    const filtered = disconnectedUsers.filter(user =>
      user.username.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  // console.log("filtered users :", filteredUsers)

  // console.log("disconnected users navbar : ", disconnectedUsers)

  useEffect(() => {
    // Skip the first render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const fetchCurrentUser = async () => {
      try {
        const fullName = userAttributes.name;

        const capitalizedFirstName =
          fullName.split(' ')[0].charAt(0).toUpperCase() + fullName.split(' ')[0].slice(1);

        setUserName(capitalizedFirstName);

      } catch (error) {
        console.log('Error retrieving current user:', error);
      }
    };

    fetchCurrentUser();
  }, [userAttributes.name]);

  useEffect(() => {
    // Skip the first render
    if (isInitialPicture.current) {
      isInitialPicture.current = false;
      return;
    }

    const fetchCurrentImage = async () => {
      try {

        setCurrentprofilePicture(profilePicture);

      } catch (error) {
        console.log('Error retrieving current user:', error);
      }
    };

    fetchCurrentImage();
  }, [profilePicture]);

  const openUserOption = () => {
    setUserOption(!userOption);
  }

  const handleLogout = async () => {
    try {

      setUserOption(false);
      setLogoutInProgress(true);

      localStorage.removeItem('session-username-admin');
      localStorage.removeItem('session-userID-admin');
      socket.disconnect();
      await Auth.signOut();
      setAuthData(null);
      setWidgetData(null)
      setDisconnectedUsers([])
      setConnectedUsers([])
      navigate('/login');
    } catch (error) {
      console.log('Error signing out:', error);
    } finally {
      setLogoutInProgress(false);
    }
  };


  const handleCloseSearchBar = () => {
    setExpandedInput(false)
    setFilteredUsers([])
    setSearchQuery("")
  }

  const handleFilterUserClick = (user) => {
    console.log("......user.isConversation", user)
    console.log("......user.isConversation", user.isConversation)


    if (user.isConversation && user.isConversation === true) {
      navigate(`/history/allchats/${user.sessionID}`);
      setExpandedInput(false)
      setFilteredUsers([])
      setSearchQuery("")
    } else {
      console.log("====> user.sessionID......", user.sessionID)
      navigate(`/visitor/${user.sessionID}`);
      setExpandedInput(false)
      setFilteredUsers([])
      setSearchQuery("")
    }

  };







  // useEffect(() => {

  //   const fetchData = async () => {


  //     try {
  //       const response = await axios.get('http://5.161.184.34:4200/getProfilePicture', {
  //         params: {
  //           username: userAttributes.email,
  //         }
  //       });
  //       console.log('Success:', response.data.data.profilePicture);

  //       const uintArray = new Uint8Array(response.data.data.profilePicture.data);
  //       const binary = uintArray.reduce((str, byte) => str + String.fromCharCode(byte), '');
  //       const b64 = btoa(binary);
  //       const newImageUrl = `data:image/png;base64,${b64}`

  //       setImageUrl(newImageUrl);
  //     } catch (error) {
  //       // Handle errors
  //       console.log("Not", error)
  //       console.error('Error:', error.response ? error.response.data : error.message);
  //     }
  //   };

  //   fetchData(); // Invoke the asynchronous function


  // }, [userAttributes.email]);


  // Function to render highlighted text
  const renderHighlightedText = (text, highlight) => {
    // Split the text into parts based on the highlight
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ color: 'blue' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </span>
    );
  };



  return (

    <div className={styles['main-navbar']}>


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={logoutInProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div style={{ position: "relative", marginLeft: "25px", flex: expandedInput ? "1" : "", marginRight: expandedInput ? "22px" : "" }}>
        <div className={`${expandedInput === true ? styles.search_input_container : styles.search_input_container_width}`}>
          <div className={styles.search_icon}>
            <BsSearch />
          </div>
          <input onFocus={() => setExpandedInput(true)} type="text" className={styles.search_bar} placeholder="Search" value={searchQuery} onChange={handleSearchInputChange} />

          {expandedInput && <div className={styles.close_icon}>
            <IoIosCloseCircle cursor="pointer" style={{ width: "25px", height: "25px" }} onClick={handleCloseSearchBar} />
          </div>}

        </div>


        {/* if less than 2 characters */}
        {(searchQuery.length >= 1 && searchQuery.length < 2 && expandedInput) &&
          <div className={styles.filterUsersList_container_char_condition} style={{ justifyContent: "center" }}>
            <div className={styles.notFound_container}>
              {/* <MdOutlineSearchOff style={{ width: "90px", height: "90px" }} /> */}
              <span style={{ fontSize: "12px", padding: "2px 20px" }} className={styles.filterUsers_title}>Enter more than 2 characters.</span>
            </div>
          </div>
        }

        {/* if not users found */}
        {(!filteredUsers.length && searchQuery.length >= 2) &&
          <div className={styles.filterUsersList_container_notFound} style={{ justifyContent: "center" }}>
            <div className={styles.notFound_container}>
              <MdOutlineSearchOff style={{ width: "50px", height: "50px", color: "#A0AEC0" }} />
              <h1 style={{ fontSize: "15px" }} className={styles.filterUsers_title}>We could not find any result matching your search.</h1>
            </div>
          </div>
        }

        {/* to show filteer users list */}
        {filteredUsers.length > 0 &&
          <div className={styles.filterUsersList_container}>
            <h1 className={styles.filterUsers_title}>Found Users</h1>
            <ul className={styles.ul_container}>
              {
                filteredUsers.map(filterUser => {
                  return <li className={styles.li_container} onClick={() => handleFilterUserClick(filterUser)}>
                    {renderHighlightedText(filterUser.username, searchQuery)}
                    {/* <span>{filterUser.username}</span> */}
                    <span style={{ fontSize: "10px" }}>Country: {filterUser.country_name}</span>
                  </li>
                })
              }
            </ul>
          </div>
        }

        {filteredUsers.length > 0 && <div className={styles.overlay}></div>}
        {(!filteredUsers.length && searchQuery) && <div className={styles.overlay}></div>}
      </div>

      <div className={styles['nav-profile-opt']}>
        <div className={styles['nav-profile-opt-right']} >

          <div className={styles.bell_icon}>
            <BsBellFill style={{ color: "grey" }} />
          </div>

          <div className={styles.upgrade_version}>
            <span className={styles.upgrade_text}>Upgrade for full featured version</span>
          </div>

          <div className={styles.upgrade_btn_container}>
            <button className={styles.upgrade_btn}>Upgrade</button>
          </div>


          <div className={styles.profile_section} onClick={openUserOption} ref={dropdownRef}>
            <div className={styles.userAvatarContainer}>
              {currentprofilePicture ? (
                <img src={currentprofilePicture} alt="Profile" className={styles.profileImage} />
              ) : (
                <Avatar sx={{ bgcolor: "#223952" }}>{userAttributes.name.charAt(0).toUpperCase()}</Avatar>
              )}
              <div className={styles.notificationDot} />
            </div>

            <div className={styles.profile_text_container}>
              <span className={styles.profile_text}>{userName.split(' ')[0].charAt(0).toUpperCase() + userName.split(' ')[0].slice(1)}</span>
              <span className={styles.profile_owner}>Owner</span>
            </div>
          </div>


          {userOption && (
            <UserOptionsDropdown setUserOption={setUserOption} userName={userAttributes.name} handleLogout={handleLogout} currentprofilePicture={currentprofilePicture} dropdownRef={dropdownRef} />
          )}

        </div>
      </div>
    </div >
  )
}
export default Navbar