// ScriptTagStep.jsx
import React from 'react';
import { TextField } from '@mui/material';
import styles from '../Signup.module.css'; // Import your CSS module




const ScriptTagStep = ({ formFields }) => (
  <>
  <div>
    <div>
    <p className={styles['code-snippet']}> Paste this code snippet just before the {'</body>'} tag</p>
    </div>

    <div className={styles['grayDiv']} >
      <TextField
        id="outlined-multiline-static"
        label="Script Text"
        multiline
        rows={10}
        variant="outlined"
        fullWidth
        value={formFields.scriptText}
        disabled
        style={{ backgroundColor: 'rgb(249, 249, 249)' }}
      />
    </div>

  </div>
  
  </>
);

export default ScriptTagStep;
