import styles from "./notifications.module.css"

import { HiBell } from "react-icons/hi2";
import { styled } from '@mui/material/styles';

import { useRef, useState } from "react";
import Alert from '@mui/material/Alert';
import BasicTable from "./BasicTable/BasicTable";
import Switch from '@mui/material/Switch';


export const Notifications = ({audioFilesData}) => {

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <div className={styles.installation_container}>
            <div className={styles.top_bar}>
                <div className={styles.personal_details_container}>
                    <HiBell className={styles.account_icon} />
                    <p className={styles.p_text}>Notifications for John</p>
                </div>
                <div>
                    <button className={styles.saveButton}>Save</button>
                </div>
            </div>
            <div className={styles.installation_guide_container}>
                {/* right side */}
                <div className={styles.right_side}>
                    {/* <div className={styles.notification_error_container}>
                        <Alert severity="error" className={styles.notification_alert}>
                            <span className={styles.span_1}>Notifications are disabled.</span>
                            <span className={styles.span_2}> You might miss some incoming chats.</span>
                            <a className={styles.notification_link} href=""> Enable notifications</a>
                        </Alert>
                    </div> */}
                    {/* top table */}
                    <div className={styles.top_section}>
                        {/* table */}
                        <div>
                            <BasicTable audioFilesData={audioFilesData} />
                        </div>
                    </div>
                    <div className={styles.bottom_section}>
                        {/* <div className={styles.widget_container}>
                            <p style={{ margin: "0px", fontSize:"15px" }}>Enable widget sounds</p>
                            <IOSSwitch sx={{ m: 1 }} />
                        </div> */}
                        <div>
                            {/* <button>Save</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}