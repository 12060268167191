import styles from "./operators.module.css"

import { HiBell } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import Alert from '@mui/material/Alert';
import BasicTable from "./BasicTable/BasicTable";
import { addUser, fetchOrganizationData } from "../../../../Service/API.js";
import { useAuth } from "../../../../Service/AuthContext.js";
import { IoIosInformationCircle } from "react-icons/io";
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { TextField, } from '@mui/material';
import CustomSelect from "../../../../Components/CustomSelect/CustomSelect.js";
import SuccessSnackbar from "../../../../Components/SuccessSnackbar/SuccessSnackbar.js";
import ErrorSnackbar from "../../../../Components/ErrorSnackbar/ErrorSnackbar.js";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { MdEmail } from "react-icons/md";


const CssTextField = styled(TextField)({
    // '& label.Mui-focused': {
    //   color: '#f37440',
    // },x
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#f37440',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DBDBDB',
        },

        "& input::placeholder": {
            fontSize: "13px"
        },
        width: '400px',
        height: "58px",
        borderRadius: "10px"

        // '&:hover fieldset': {
        //   borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //   borderColor: '#f37440',
        // },
    },
});



export const Operators = ({ organizationData, setOperatorAdded, operatorAdded }) => {
    const [changesInProgress, setChangesInProgress] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState({ isOpen: false, message: "" });
    const [isModalOpen, setModalOpen] = useState(false);
    const [isOperatorModalOpen, setIsOperatorModalOpen] = useState(false);
    const [operatorsList, setOperatorsList] = useState([]);
    const [organizationName, setOrganizationName] = useState("");
    const [nameError, setNameError] = useState({ isError: false, message: '' });
    const [emailError, setEmailError] = useState({ isError: false, message: '' });
    const [dropdownError, setDropdownError] = useState({ isError: false, message: '' });
    const [operator, setOperator] = useState({ name: "", email: "", role: "" });
    const [addedOperator, setAddedOperator] = useState("")
    const { widgetID, userAttributes } = useAuth();
    const options = [
        { value: 'Moderator', label: 'Moderator' },
    ];


    useEffect(() => {
        const fetchData = async () => {
            console.log("widgetID in setting=====> : ", widgetID);
            try {
                const orgData = await fetchOrganizationData(widgetID);
                setOperatorsList(orgData[0].admins);
                setOrganizationName(orgData[0].companyName)
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };

        if (organizationData.admins) {
            setOperatorsList(organizationData.admins);
            setOrganizationName(organizationData.companyName)
        } else {
            fetchData();
        }
    }, [organizationData.admins, widgetID]);



    const modalRef = useRef();
    const operatorAddedModalRef = useRef()

    const onClose = () => {
        setModalOpen(!isModalOpen)
    }

    const onOperatorModalClose = () => {
        setIsOperatorModalOpen(!isOperatorModalOpen)
        setOperator({ name: "", email: "", role: "" })

    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (operatorAddedModalRef.current && !operatorAddedModalRef.current.contains(event.target)) {
                if (isOperatorModalOpen) onOperatorModalClose()
            }
        };

        // Add event listener to handle clicks outside of modal
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onOperatorModalClose]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if (isModalOpen) onClose();
            }
        };

        // Add event listener to handle clicks outside of modal
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);


    const handleOperatorForm = (e) => {
        setOperator((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))

        if (e.target.name === "name") {
            setNameError({ isError: false, message: '' });
        }

        if (e.target.name === "email") {
            setEmailError({ isError: false, message: '' });
        }



    }

    const saveOperator = async () => {

        const trimmedName = operator.name.trim();
        const trimmedEmail = operator.email.trim();
        const trimmedRole = operator.role.trim();

        // Clear any previous errors
        setNameError({ isError: false, message: '' });
        setEmailError({ isError: false, message: '' });
        setDropdownError({ isError: false, message: '' });

        if (!trimmedName) {
            // Name is empty
            setNameError({ isError: true, message: 'Name field is required' });
        }

        if (!trimmedEmail) {
            // Email is empty
            setEmailError({ isError: true, message: 'Email field is required' });
        }

        if (!trimmedRole) {
            // Role is empty
            setDropdownError({ isError: true, message: 'Dropdown field is required' });
        }

        if (!trimmedName || !trimmedEmail || !trimmedRole) {
            // At least one of the fields is empty
            return;
        }

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValidEmail = re.test(trimmedEmail)
        if (!isValidEmail) {
            setEmailError({ isError: true, message: "Please enter valid email address" })
            return
        }



        setOperatorsList((prevState) => ([
            ...prevState,
            operator
        ]))

        const userData = {
            name: operator.name,
            email: operator.email,
            // password: "Austin123$",
            companyURL: userAttributes.address,
            companyName: userAttributes.website,
            widgetId: widgetID,
            groupName: operator.role
        };

        setChangesInProgress(true)
        //API
        try {
            const response = await addUser(userData);
            if (response) {
                setModalOpen(!isModalOpen)
                setOperatorAdded(!operatorAdded)
            }
            setChangesInProgress(false)
            setIsSnackbarOpen({ isOpen: true, message: "User added successfully..." })
            setIsOperatorModalOpen(true)

        } catch (error) {
            console.log("Error in adding operator : ",)
            setIsErrorSnackbarOpen({ isOpen: true, message: "User added successfully..." })
        }

    }

    const operator_modal_form = () => {

        return (
            <div ref={modalRef} style={{ display: isModalOpen ? "block" : "none", textAlign: 'center' }} className={styles.modalStyles}>
                <div className={styles.modal_title_container}>
                    <h4 className={styles.modal_title}>Add Operator</h4>
                    <IoClose className={styles.close_icon} onClick={() => setModalOpen(!isModalOpen)} />
                </div>
                {/* <div className={styles.input_container}>
                    <label htmlFor="">Name</label>
                    <input onChange={handleOperatorForm} className={styles.custom_input} value={operator.name} type="text" name="name" id="" />
                </div> */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', padding: "10px" }}>



                    <CssTextField

                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                        placeholder="Full Name"
                        value={operator.name}
                        onChange={handleOperatorForm}
                        required
                        type="text"
                        name="name"
                        id=""
                        helperText={nameError.message}
                        error={nameError.isError}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M16.6401 22H7.36009C6.34927 21.9633 5.40766 21.477 4.79244 20.6742C4.17722 19.8713 3.95266 18.8356 4.18009 17.85L4.42009 16.71C4.69613 15.1668 6.02272 14.0327 7.59009 14H16.4101C17.9775 14.0327 19.3041 15.1668 19.5801 16.71L19.8201 17.85C20.0475 18.8356 19.823 19.8713 19.2077 20.6742C18.5925 21.477 17.6509 21.9633 16.6401 22Z" fill="#083A5E" />
                                        <path d="M12.5001 12H11.5001C9.29096 12 7.50009 10.2092 7.50009 8.00001V5.36001C7.49743 4.46807 7.85057 3.61189 8.48127 2.98119C9.11197 2.35049 9.96815 1.99735 10.8601 2.00001H13.1401C14.032 1.99735 14.8882 2.35049 15.5189 2.98119C16.1496 3.61189 16.5028 4.46807 16.5001 5.36001V8.00001C16.5001 9.06088 16.0787 10.0783 15.3285 10.8284C14.5784 11.5786 13.561 12 12.5001 12Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <CssTextField

                        variant="outlined"
                        fullWidth
                        FormHelperTextProps={{ style: { color: '#d32f2f', width: '370px', marginLeft: '0px', } }}
                        placeholder="Email"
                        value={operator.email}
                        onChange={handleOperatorForm}
                        required
                        type="email"
                        name="email"
                        id=""
                        // helperText="Incorrect entry."
                        // error
                        helperText={emailError.message}
                        error={emailError.isError}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99984 3.33331H14.9998C16.8408 3.33331 18.3332 4.8257 18.3332 6.66665V14.1666C18.3332 16.0076 16.8408 17.5 14.9998 17.5H4.99984C3.15889 17.5 1.6665 16.0076 1.6665 14.1666V6.66665C1.6665 4.8257 3.15889 3.33331 4.99984 3.33331ZM11.1665 11.2916L16.4498 7.37498C16.6856 7.20164 16.7377 6.87067 16.5665 6.63331C16.4849 6.51999 16.3609 6.44452 16.2227 6.42411C16.0846 6.40371 15.9441 6.44009 15.8332 6.52498L10.4915 10.4166C10.3578 10.5567 10.1726 10.636 9.979 10.636C9.78537 10.636 9.60017 10.5567 9.4665 10.4166L4.1665 6.52498C4.05615 6.44276 3.91756 6.40797 3.78146 6.4283C3.64536 6.44864 3.523 6.52243 3.4415 6.63331C3.26852 6.86851 3.31681 7.19908 3.54984 7.37498L8.79984 11.25C9.12652 11.5599 9.55792 11.7354 10.0082 11.7416C10.4369 11.7416 10.8501 11.581 11.1665 11.2916Z" fill="#083A5E" />
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <div className={styles.input_container}>
                    <label htmlFor="">Email</label>
                    <input onChange={handleOperatorForm} className={styles.custom_input} value={operator.email} type="text" name="email" id="" />
                </div> */}
                    {/* <div className={styles.input_container}>
                    <label htmlFor="">Role</label>
                    <select name="role" id="" onChange={handleOperatorForm} className={styles.custom_input} value={operator.role}>
                        <option> </option>
                        <option value="Admin">Admin</option>
                        <option value="Moderator">Moderator</option>

                    </select>
                </div> */}
                    <CustomSelect showError={dropdownError.isError} onChange={handleSelectChange} width={"400px"} height={"59px"} options={options} placeholder={"Select Role"} />
                </div>
                <div className={styles.addOperatorButton_container}>

                    <button className={styles.add_ope_btn} onClick={saveOperator}>Add an Operator</button>
                </div>

            </div>
        )
    }

    const operator_added_modal = () => {

        return (
            <div ref={operatorAddedModalRef} style={{ display: isOperatorModalOpen ? "block" : "none", textAlign: 'center' ,width:"550px" }} className={styles.modalStyles}>
                {/* <div className={styles.modal_title_container} style={{ justifyContent: 'end' }}>
                    <h4 className={styles.modal_title}>Add Operator</h4>
                    <IoClose className={styles.close_icon} onClick={onOperatorModalClose} />
                </div> */}

                <div style={{ display: 'flex', flexDirection: 'column', gap: '35px', padding: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <span className={styles.email_icon_container}>
                            <MdEmail style={{ width: "35px", height: "35px", color: "#f37440" }} />
                        </span>
                    </div>
                    <div style={{ alignSelf: 'center' }}>
                        <p className={styles.check_email}>Please Check Your Email</p>
                        <div className={styles.email_container}>
                            <p style={{ margin: "0", color: "#8E8EA0" }}>You're almost there! We have sent an email to</p>
                            <p className={styles.email}>{operator.email}</p>
                        </div>
                        <p style={{ color: "#8E8EA0", margin:"0" }}>Just go to that email to get your login credentials.
                            If you don't see it, you may need to <span style={{ color: "#083A5E", fontWeight: "700" }}>check your spam</span> folder.
                        </p>
                    </div>
                    <div>
                        <button className={styles.close_email_modal} onClick={onOperatorModalClose}>Close</button>
                    </div>
                </div>

            </div>
        )
    }

    const handleSelectChange = (selectedOption) => {
        // Assuming the selectedOption has a "value" property
        const selectedRole = selectedOption ? selectedOption.value : "";

        setOperator(prevOperator => ({
            ...prevOperator,
            role: selectedRole
        }));
        setDropdownError({ isError: false, message: '' });
    };

    return (
        <div className={styles.installation_container}>
            <div className={styles.top_bar}>
                <div className={styles.personal_details_container}>
                    {/* <HiBell className={styles.account_icon} /> */}
                    <p className={styles.p_text}>Operators</p>
                </div>
                <div className={styles.btn_container}>
                    {/* <button className={styles.add_dep_btn}>Add new department</button> */}

                    <button
                        className={styles.add_ope_btn}
                        onClick={() => setModalOpen(!isModalOpen)}
                        disabled={operatorsList.length >= 2}
                    >
                        Add an Operator
                    </button>
                </div>
                {operator_modal_form()}

                <div style={{ display: isModalOpen ? "block" : "none" }} className={styles.overlayStyles}></div>
            </div>
            <div className={styles.installation_guide_container}>
                {/* right side */}
                <div className={styles.right_side}>
                    {/* top table */}
                    <div className={styles.top_section}>
                        {/* table */}
                        <div>
                            <p> <IoIosInformationCircle style={{ color: "#f27340", width: "20px", height: "20px", marginBottom: "3px", opacity: "0.8" }} /> Maximum 2 operators are allowed</p>
                            <BasicTable setOperatorAdded={setOperatorAdded} operatorAdded={operatorAdded} organizationName={organizationName} operatorsList={operatorsList} />
                        </div>
                    </div>
                    <div className={styles.bottom_section}>
                    </div>
                </div>
            </div>

            {operator_added_modal()}

            <div style={{ display: isOperatorModalOpen ? "block" : "none" }} className={styles.overlayStyles}></div>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={changesInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SuccessSnackbar isSnackbarOpen={isSnackbarOpen} setIsSnackbarOpen={setIsSnackbarOpen} />
            <ErrorSnackbar isErrorSnackbarOpen={isErrorSnackbarOpen} setIsErrorSnackbarOpen={setIsErrorSnackbarOpen} />
        </div>
    )
}