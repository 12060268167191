
import { useEffect, useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import { FaUser, FaSignOutAlt, FaLifeRing } from 'react-icons/fa';
import { FaBell } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import styles from './UserOptionsDropdown.module.css'; // Import styles if needed


const UserOptionsDropdown = ({ userName, handleLogout, setUserOption, dropdownRef, currentprofilePicture }) => {
    const [dropdowns, setDropdowns] = useState({ status: "Online", url: "www.stampasolutions.com" })
    console.log("dropDowns : ", dropdowns)
    const navigate = useNavigate();

    const handleDropdown = (e) => {
        setDropdowns((prevState) => ({
            ...prevState, [e.target.name]: e.target.value
        }))
    }
    const handleProfile = () => {
        navigate('/settings/account');
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !event.target.closest(`.${styles['user-option']}`)
            ) {
                setUserOption(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, [dropdownRef]);

    return (
        <>
            <div className={styles['user-option-overlay']} onClick={() => setUserOption(false)} />
            <div className={styles['user-option']}>
                {/* Other user information */}
                <div className={styles['user-option-content']}>


                    {currentprofilePicture ? (
                        <img src={currentprofilePicture} alt="Profile" className={styles.profileImage} />
                    ) : (
                        <Avatar sx={{ fontSize: "1.5rem", bgcolor: "orange", height: '50px', width: '50px' }}>
                            {userName.charAt(0).toUpperCase()}
                        </Avatar>
                    )}

                    <p className={styles['username-account-text']}>{userName}</p>
                    <p className={styles['trial-account-text']}>Trial Account (0 days left)</p>

                    {/* dropdowns */}
                    <div className={styles.dropdown_container}>
                        <div style={{ position: 'relative' }}>
                            <div className={styles.dropdown_icon}>
                                <FaBell color='white' />
                            </div>
                            <select className={styles.dropdown} name="status" id="status" onChange={handleDropdown} value={dropdowns.status}>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                            </select>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <div className={styles.dropdown_icon}>
                                <BiWorld color='white' />
                            </div>
                            <select className={styles.dropdown} name="url" id="url" onChange={handleDropdown} value={dropdowns.url}>
                                <option value="www.stampasolutions.com">www.stampasolutions.com</option>
                                <option value="www.stampasolutions.support">www.stampasolutions.support</option>
                            </select>
                        </div>
                    </div>

                    {/* Icon buttons */}
                    <div className={styles['icon-buttons']}>
                        <div onClick={handleProfile} className={styles['icon-buttons-container']} >
                            <span className={styles['icon-button-styling']}><FaUser size={23} /></span>
                            <span> Profile</span>
                        </div>
                        <div onClick={handleLogout} className={styles['icon-buttons-container']} >
                            <span className={styles['icon-button-styling']}><FaSignOutAlt size={23} /></span>
                            <span> Logout</span>
                        </div>
                        <div className={styles['icon-buttons-container']} >
                            <span className={styles['icon-button-styling']}><FaLifeRing size={23} /></span>
                            <span> Support</span>
                        </div>
                    </div>
                    <p className={styles.below_text}>Show logged in devices</p>
                </div>
            </div>
        </>
    );
};

export default UserOptionsDropdown;
