// SoundContext.js
import React, { createContext, useContext } from 'react';
import  IncomingvVistorMessage from '../Audio/IncomingvVistorMessage.mp3'
import  NewVistor from '../Audio/NewVistor.mp3'

import  inactiveChatMessage from '../Audio/inactiveChatMessage.mp3'

import  ActiveChatMessage from '../Audio/ActiveChatMessage.mp3'


const SoundContext = createContext();


export const SoundProvider = ({ children }) => {
  // You can add more sounds as needed
  const sounds = {


    IncomingvVistorMessage: new Audio(IncomingvVistorMessage),
    NewVistor: new Audio(NewVistor),
    inactiveChatMessage: new Audio(inactiveChatMessage),
    ActiveChatMessage: new Audio(ActiveChatMessage),
  };

  const playSound = (soundKey) => {
    const sound = sounds[soundKey];
    if (sound) {
      sound.play();
    }
  };

  return (
    <SoundContext.Provider value={{ playSound }}>
      {children}
    </SoundContext.Provider>
  );
};

export const useSound = () => {
  return useContext(SoundContext);
};
