import React, { useState } from 'react';
import Select from 'react-select';

export default function CustomSelect({
    onChange,
    width,
    height,
    options,
    placeholder: propPlaceholder,
    borderColor,
    optionSelectedColor,
    optionHoverColor,
    showError,
}) {
    const [selectedOption, setSelectedOption] = useState(null);
    const placeholder = propPlaceholder || 'Select an option'; // Use propPlaceholder if provided, otherwise use the default

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (typeof onChange === 'function') {
            onChange(selectedOption);
        } else {
            console.log("onChange not passed...");
        }
    }

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          width: width || '100%',
          height: height,
          borderRadius: "10px",
          borderWidth: '0.8px',
          border: `1px solid ${showError ? '#d3302f' : (state.isFocused ? 'blue' : borderColor || '#dbdbdb')}`,
          textAlign: 'left',
          '&:hover': {
            borderColor: 'black',
            border: showError? "1px solid #d3302f" : "1px solid black"
          },
      
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '5px',
          zIndex: "10"
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? optionSelectedColor || '#7998ba' : 'white',
          '&:hover': {
            backgroundColor: state.isSelected ? null : optionHoverColor || '#ececec',
          },
          
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#a2a2a2', // Set the placeholder color
          fontSize: '14px', // Set the placeholder font size
        }),
      }

    return (
      <>
            <Select
                styles={customStyles}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                isClearable
                placeholder={placeholder} // Show placeholder only if there's no error
            />
            {showError && !selectedOption && (
               <div style={{ 
                color: '#d3302f', 
                marginTop: '8px',
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: 400,
                fontSize: '0.75rem',
                lineHeight: 1.66,
                letterSpacing: '0.03333em',
                textAlign: 'left',
                marginTop: '3px',
                marginRight: '14px',
                marginBottom: 0
              }}>
                Please select an option
              </div>
              
            )}
        </>
    );
}
