import React from "react";
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./settings.module.css";
import { useEffect, useState } from "react";
import { Installation } from "./settings_components/Installation/Installation";
import { Account } from "./settings_components/Account/Account";
import MenuSideBar from "../../Components/MenuSidebar/MenuSideBar";
import Navbar from "../../Components/Navbar/Navbar";
import { Notifications } from "./settings_components/Notifications/Notifications";
import { Operators } from "./settings_components/Operators/Operators";
import { SettingsSideMenu } from "./settings_components/Settings_menu/SettingsSideMenu";
import * as api from '../../Service/API.js'; // Import your API functions
import { useAuth } from "../../Service/AuthContext.js";


export const Settings = () => {
    const [page, setPage] = useState("");
    const [organizationData, setOrganizationData] = useState("")
    const [operatorAdded, setOperatorAdded] = useState(true)
    const [audioFilesData, setAudioFilesData] = useState([])
    const { widgetID } = useAuth();
    const navigate = useNavigate();

    const handleNavigation = (e) => {
        const selectedPage = e.currentTarget.id;
        setPage(selectedPage);
        navigate(`/settings/${selectedPage}`);
    };
    useEffect(() => {
        const fetchAudioData = async () => {
            try {
                const audioFiles = await api.listAudioFiles();
                console.log("audioFiles=====>", audioFiles);

                const modifyAndFilterObjects = (array) => array
                    .filter(obj => obj.name.trim() !== '')  // Remove objects without a name
                    .map(obj => ({
                        label: obj.name,
                        value: `https://d2ja7yn3ys8h9s.cloudfront.net/${obj.key}`
                    }));

                // Loop through each array in audioFiles, modify, and filter objects
                Object.keys(audioFiles).forEach(key => {
                    audioFiles[key] = modifyAndFilterObjects(audioFiles[key]);
                });

                setAudioFilesData(audioFiles)
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };

        fetchAudioData();

    }, [widgetID]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const orgData = await api.fetchOrganizationData(widgetID);
                setOrganizationData(orgData[0])
            } catch (error) {
                console.error('Error fetching Data:', error);
            }
        };

        fetchData();
    }, [widgetID, operatorAdded]);


    useEffect(() => {
        const location = window.location.pathname
        console.log("location", location)

        if (location === "/settings") {
            navigate('/settings/account');
            setPage("account");
        } else {
            const match = location.match(/\/settings\/(\w+)/);
            if (match) {
                const extractedValue = match[1];
                setPage(extractedValue);
            }
            navigate(location);
        }

    }, []);


    const handleClick = async () => {
        console.log("Testing");
    };

    return (
        <>
            <MenuSideBar page={"Dashboard"} handleClick={handleClick} ShowIndicatior={true} />
            <div className="main-mychat">
                <Navbar />
                <div style={{ display: "flex" }}>
                    <div className={styles.settings_container}>
                        <SettingsSideMenu page={page} handleNavigation={handleNavigation} />

                        {/* Use Routes and Outlet for nested routes */}
                        <Routes>
                            <Route path="account" element={<Account />} />
                            <Route path="installation" element={<Installation organizationData={organizationData} />} />
                            <Route path="notification" element={<Notifications audioFilesData={audioFilesData} />} />
                            <Route path="operators" element={<Operators operatorAdded={operatorAdded} setOperatorAdded={setOperatorAdded} organizationData={organizationData} />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>

    );
};

