import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import socket from "../../../../socketjs";
import styles from "./header.module.css";
import closingDoorSound from "../../../../Audio/closingDoorSound.mp3"
import { useConnectedUsersContext } from "../../../../Service/ConnectedUsersContext";
import { useAuth } from "../../../../Service/AuthContext";

const Header = ({ page, selectedUser,username, setChatState, chatState, currentAuthUsername, selectedUserSessionId }) => {
  const [showOptions, setShowOptions] = useState(false);
  const { connectedUsers, updateConnectedUsers, updateDisconnectedUsers, disconnectedUsers } = useConnectedUsersContext();
  const { widgetID } = useAuth();
  console.log("....", page)


  const handleOptionsToggle = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option) => {
    if (option === "endChat") {
      // Find the user with the matching sessionID
      socket.emit('endChat', { userIDEnd: selectedUserSessionId, widgetID: widgetID, adminUsername: currentAuthUsername });


      const updatedUsers = connectedUsers.map(user => {
        if (user.sessionID === selectedUserSessionId) {
          // Update properties for ending the chat
          return {
            ...user,
            isChatClosed: true,
            isActiveChat: false,
            // adminAssigned: "N/A",
            // adminAssignedUsername: "N/A"
          };
        }
        return user;
      });

      const updateDisconnectedUsersList = disconnectedUsers.map(user => {
        if (user.sessionID === selectedUserSessionId) {
          // Update properties for ending the chat
          return {
            ...user,
            isChatClosed: true,
          };
        }
        return user;
      });


      const isConnectedUserDisconnected = !updateDisconnectedUsersList.some(user => user.sessionID === selectedUserSessionId);

      if (isConnectedUserDisconnected) {
        const connectedUser = connectedUsers.find(user => user.sessionID === selectedUserSessionId);
        if (connectedUser) {
          updateDisconnectedUsersList.push({
            ...connectedUser,
            isChatClosed: true,
          });
        }
      }


      updateConnectedUsers(updatedUsers);
      updateDisconnectedUsers(updateDisconnectedUsersList);

      // Additional logic for ending the chat
      const audio = new Audio(closingDoorSound);
      audio.play();

      setShowOptions(false);
      setChatState((prevState) => ({
        ...prevState,
        isChatEnded: true,
      }));
    } else if (option === "muteChat") {
      // Add logic for muteChat if needed
    }
  };


  return (
    <div className={styles['top-username-conversarion']}>
      <div style={{ backgroundColor: 'white', paddingLeft: '10px', textAlign: 'start', paddingTop: '5px' }}>
        {username && <p className={`${styles.usernameStyle} mb-1`}>{username}</p>}
        {(username) && (
          <p className={`${styles.attendedUserText} mb-2`}>
            Attended By <span className={styles['attendedUserTextStyle']} >{page === "history" ? selectedUser.AdminUsername : currentAuthUsername}</span>
          </p>
        )}
      </div>

      {/* Three dot options */}
      {page !== "visitor" && (
        <div className={styles['conversation-option']}>
          <BsThreeDotsVertical
            style={{ cursor: "pointer", marginRight: "1.5rem", fontSize: "22px" }}
            onClick={handleOptionsToggle}
          />
          {showOptions && (
            <div className={styles["options"]}>
              <div onClick={() => handleOptionClick("endChat")} className={styles["option"]}>
                <IoClose style={{ width: "16px", height: "16px", marginRight: "8px" }} />
                End chat
              </div>
              {/* Add more options as needed */}
            </div>
          )}
        </div>
      )}

    </div>
  );
};

export default Header;
