import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


export default function BotFlowAlerts({showAlert, showAlertDelete, showAlertDuplicate, handleCloseAlert, handleCloseAlertDelete, handleCloseDuplicate}) {
    
    return (
        <>
            {/* Alerts */}
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '9999', // Adjust the z-index as needed
                }}
            >
                <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                        Changes saved successfully
                    </Alert>
                </Snackbar>
            </div>
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '9999', // Adjust the z-index as needed
                }}
            >
                <Snackbar open={showAlertDelete} autoHideDuration={4000} onClose={handleCloseAlertDelete}>
                    <Alert onClose={handleCloseAlertDelete} severity="warning" sx={{ width: '100%' }}>
                        Question has been deleted
                    </Alert>
                </Snackbar>
            </div>
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    zIndex: '9999', // Adjust the z-index as needed
                }}
            >
                <Snackbar open={showAlertDuplicate} autoHideDuration={4000} onClose={handleCloseDuplicate}>
                    <Alert onClose={handleCloseDuplicate} severity="info" sx={{ width: '100%' }}>
                        Question has been duplicated
                    </Alert>
                </Snackbar>
            </div>
            {/* Alerts */}
        </>
    )
}