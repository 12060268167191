import React from 'react';
import { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
// import '../../Styles/Online.css'; 
import socket from '../../../socketjs.js';
import { AiOutlineClockCircle } from 'react-icons/ai';
import B1 from '../../../Img/Image1.png'
import styles from "./history.module.css"
import { useUser } from '../../../Service/SelectedUserContext.js';
import { useAuth } from '../../../Service/AuthContext.js';
import { useParams } from 'react-router-dom';

const History = (props) => {
  let userList = props.connectedUsers;
  const pageSub = props.pageSub;
  const { selectedUser, setSelectedUser } = useUser();
  const { widgetID } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  useEffect(() => {

    if (userList.length !== 0) {
      setIsLoading(false);
    }
  }, [userList]);

  localStorage.setItem('HistoryUsers', JSON.stringify(userList));


  const userName_from_click = (selectedUser) => {
    const sessionID = selectedUser.sessionID
    selectedUser.page = 'history';
    setSelectedUser(selectedUser);
    props.selectUser(selectedUser);
    if (selectedUser.unreadCount && selectedUser.unreadCount !== "0") {
      socket.emit('messages read', sessionID, widgetID);
      const updatedUserList = userList.map(u => {
        if (u.sessionID === selectedUser.sessionID) {
          u.unreadCount = "0";
        }
        return u;
      });


      userList = updatedUserList
    }
  };

  useEffect(() => {
    if (userList.length > 0) {

      if (params?.hasOwnProperty("sessionID")) {
        const userSelected = userList.find(user => user.sessionID === params["sessionID"]);
        userName_from_click(userSelected);
      } else {
        console.error("Session ID not found in params.");
      }
    }
  }, [userList,params?.sessionID]); // Run the effect when userList changes
  



  socket.on("user-info-to-admin", (editedUsername, sessionID) => {
    for (let user of userList) {
      if (user.sessionID === sessionID) {
        user.username = editedUsername;
        break;
      }
    }
  });

  let showUsers = userList
    .filter(user => {
      if (pageSub === "All Chats") {
        return user.isConversation === true;
      } else if (pageSub === "Closed Chats") {
        return user.isChatClosed === true;
      }
      else if (pageSub === "Missed Chats") {
        return user.isConversation === true && user.isChatMissed === true;
      }
      else if (pageSub === "Archived Chats") {
        return user.isConversation === true && user.isChatArchived === true;
      }
      else if (pageSub === "Offline Chats") {
        return user.isConversation === true && user.AdminUsername === "Bot";
      }
      // Add more conditions as needed
      return false; // Default case if none of the conditions match
    })
    .sort((userA, userB) => {

      const disconnectTimeA = new Date(userA.disconnectTime);
      const disconnectTimeB = new Date(userB.disconnectTime);
      return disconnectTimeB - disconnectTimeA; // Sort in descending order
    })
    .map(user => {
      const isSelected = selectedUser && selectedUser.username === user.username;

      // Calculate the time difference
      const disconnectTime = new Date(user.disconnectTime);
      const currentTime = new Date();
      const timeDifference = currentTime - disconnectTime;
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


      let timeAgo;
      if (daysDifference >= 7) {
        const weeksDifference = Math.floor(daysDifference / 7);
        timeAgo = `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
      } else if (daysDifference > 0) {
        timeAgo = `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
      } else if (hoursDifference > 0) {
        timeAgo = `${hoursDifference}h ago`;
      } else if (minutesDifference > 0) {
        timeAgo = `${minutesDifference}m ago`;
      } else {
        timeAgo = '10s ago';
      }
      return (

        <div
          key={user._id}
          className={`${styles['main-sidebar-content-cnt']} ${isSelected ? styles.selected : styles.notSelected}`}
          onClick={() => {
            userName_from_click(user);
          }}
          ref={(ref) => {
            // Add a ref to the selected user's element
            if (isSelected) {
              ref && ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }}
        >
          <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              <span style={{ fontWeight: 500 }}>{user.username}</span>
            </div>
            <span style={{ padding: '2px 8px', borderRadius: '20px', fontSize: '11px', fontWeight: "500", color: '#40464f' }} className={styles['sidebar-top-right']}><AiOutlineClockCircle className={styles.clock} /> {timeAgo}</span>
          </div>
          <div className={styles['sidebar-cnt-tp']}>
            <div className={styles['online-icon']}>
              <ReactCountryFlag countryCode={user.country_code2} svg />
              <span className={styles['sidebar-cnt-dn-cntry']}>{user.country_name}</span>
            </div>
          </div>
          <div style={{ fontSize: '12px', marginTop: '8px', display: "flex", justifyContent: "space-between" }}>
            <span
              style={{
                display: 'inline-block',
                backgroundColor: '#fff',
                padding: '0.2rem 0.8rem',
                borderRadius: '20px',
                fontSize: '10px',
                fontWeight: '500',
                color: 'black',
                // border: '1px solid #efefef' 
              }}
              className={styles['sidebar-top-right']}
            >
              <span style={{ color: 'grey', fontWeight: 400 }}> Attended by</span>  <span style={{ color: '#4db8ff', fontWeight: 500 }}>{user.AdminUsername}</span>
              {user.AdminUsername == 'Bot' && (<img src={B1} alt='Bot' style={{ marginLeft: '5px', verticalAlign: 'middle', width: "21px" }} />)}
            </span>
            {(user.unreadCount && user.unreadCount !== "0") && (
              <div
                className={styles['unread-count']}
              >
                {user.unreadCount}
              </div>
            )}

          </div>
        </div>

      );
    });
  return (
    <div>
      {userList.length > 0 ? (
        isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '55vh', marginTop: "25px" }}>
            <div className={styles.loader}></div>
          </div>
        ) : (
          // Display the user list once loading is done
          <div style={{ marginBottom: "15%" }}>
            <div>{showUsers}</div>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '14px',
            alignItems: 'center',
            height: '63vh', // Adjust this to control the height of the container
          }}
        >
          No chats available
        </div>
      )}
    </div>

  );
};

export default History;
