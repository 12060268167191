import { RxDotFilled } from "react-icons/rx";
import styles from "./countriesChart.module.css"
import ReactCountryFlag from "react-country-flag";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { CircleFlagLanguage } from 'react-circle-flags'
import { CircleFlag } from 'react-circle-flags'


export default function CountriesChart({disconnectedUsers}) {
    

    
    
    
    const dummyData = [
        { country_name: 'Pakistan', country_code2: 'PK' },
        { country_name: 'United States', country_code2: 'US' },
        { country_name: 'United Kingdom', country_code2: 'GB' },
        { country_name: 'Pakistan', country_code2: 'PK' },
        { country_name: 'Canada', country_code2: 'CA' },
        { country_name: 'Pakistan', country_code2: 'PK' },
        { country_name: 'United States', country_code2: 'US' },
        { country_name: 'United Kingdom', country_code2: 'GB' },
        { country_name: 'Pakistan', country_code2: 'PK' },
        { country_name: 'Australia', country_code2: 'Aus' },
        { country_name: 'South Africa', country_code2: 'SA' },
        { country_name: 'Italy', country_code2: 'IT' },
        { country_name: 'Pakistan', country_code2: 'PK' },
    ]

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const unique_countries = new Set()

    const countryLabels = disconnectedUsers.map(user => {
        if (!unique_countries.has(user.country_name)) {
            unique_countries.add(user.country_name);
            return {
                country: user.country_name,
                country_code: user.country_code2
            };
        }
        return null; // Return null for elements that don't meet the condition
    }).filter(Boolean);
    console.log("labels : ", countryLabels)

    const countryCounts = countryLabels.map(label =>
        disconnectedUsers.filter(user => user.country_name === label.country).length
    );

    function calculatePercentages(values) {
        const total = values.reduce((acc, val) => acc + val, 0);

        const percentages = values.map((value, index) => ({
            country_name: countryLabels[index].country,
            country_code: countryLabels[index].country_code,
            value,
            percentage: ((value / total) * 100).toFixed(1)
        }));

        return percentages;
    }

    console.log("Percentages : ", calculatePercentages(countryCounts))
    const tableData = calculatePercentages(countryCounts)

    let topFourCountries = tableData.sort((a,b)=>b.value - a.value)
    console.log("Top 4 list before : ", topFourCountries)
    topFourCountries = topFourCountries.splice(0,4)
    console.log("Top 4 list before : ", topFourCountries)

    return (
        <div className={styles.chart_container}>
            {topFourCountries.map((item, index) => {
                const { country_name, country_code, value, percentage } = item
                return (
                    <div className={styles.chart_container_item} key={index}>
                        <CircleFlag countryCode={country_code.toLowerCase()} height="35" />

                        <div className={styles.chart_container_item_right}>
                            <div className={styles.chart_container_item_right_top}>
                                <div style={{ fontSize: "15px", color: '#a2a5a9' }}><span>{country_name}</span></div>
                                <div style={{ fontSize: "15px", color: '#a2a5a9' }}><span>{value}</span><RxDotFilled /><span>{`${percentage}%`}</span></div>
                            </div>
                            <div>
                                <BorderLinearProgress variant="determinate" value={percentage} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}