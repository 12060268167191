import React, { useState, useEffect, useMemo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { GoPrimitiveDot } from 'react-icons/go';
import styles from './activeChat.module.css';
import socket from '../../../socketjs.js';
import axios from 'axios';
import { useUser } from '../../../Service/SelectedUserContext.js';
import { useAuth } from '../../../Service/AuthContext.js';
import { useParams } from 'react-router-dom';


const ActiveChat = (props) => {
  const { connectedUsers, selectUser } = props;
  const { selectedUser, setSelectedUser } = useUser();
  const { widgetID, userAttributes } = useAuth();
  const [unreadCounts, setUnreadCounts] = useState({});
  const [isTyping, setIsTyping] = useState([]);
  const params = useParams();

  
  useEffect(() => {
    if (props.SelectedActiveChatuser !== undefined) {
      console.log('User information in ActiveChats:', props.SelectedActiveChatuser);
      userName_from_click(props.SelectedActiveChatuser)
    }
  }, [props.SelectedActiveChatuser]);


  
  useEffect(() => {
    if (connectedUsers.length > 0) {

      if (params?.hasOwnProperty("sessionID")) {
        const userSelected = connectedUsers.find(user => user.sessionID === params["sessionID"]);
        userName_from_click(userSelected);
      } else {
        console.error("Session ID not found in params.");
      }
    }
  }, [connectedUsers,params?.sessionID]); // Run the effect when userList changes


  useEffect(() => {
    const handleUnreadCountUpdate = ({ userId, unreadCount }) => {
      if (userId !== selectedUser?.sessionID) {
        setUnreadCounts((prevUnreadCounts) => ({ ...prevUnreadCounts, [userId]: unreadCount }));
      } else {
        socket.emit('messages read', userId, widgetID);
      }
    };

    socket.on('unread count update', handleUnreadCountUpdate);

    return () => {
      socket.off('unread count update', handleUnreadCountUpdate);
    };
  }, [selectedUser, widgetID]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://5.161.184.34:4200/usersCount');
        const filteredCounts = {};
        response.data.forEach((item) => {
          filteredCounts[item.sessionID] = parseInt(item.unreadCount);
        });
        setUnreadCounts(filteredCounts);
      } catch (error) {
        throw new Error(error.response.data);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let typingTimers = {};

    const handleUserTypingSidebar = ({ from }) => {
      clearTimeout(typingTimers[from]);
      typingTimers[from] = setTimeout(() => {
        setIsTyping((prevState) => prevState.filter((id) => id !== from));
      }, 1000);

      setIsTyping((prevState) => (prevState.includes(from) ? prevState : [...prevState, from]));
    };

    socket.on('userTypingSidebar', handleUserTypingSidebar);

    return () => {
      socket.off('userTypingSidebar', handleUserTypingSidebar);
      Object.values(typingTimers).forEach((timer) => clearTimeout(timer));
    };
  }, []);

  const readMessages = (userId) => {
    socket.emit('messages read', userId, widgetID);
    setUnreadCounts((prevState) => ({ ...prevState, [userId]: 0 }));
  };




  useEffect(() => {
    const handle = (newUser) => {
      if (newUser) {
        setSelectedUser(newUser);
        props.selectUser(newUser);
      }
    };

    socket.on('Newusers_to_admin', handle);

    return () => {
      socket.off('Newusers_to_admin', handle);
    };
  }, []);



  const userName_from_click = (selectedUser) => {
    selectedUser.page = 'ActiveChat';
    setSelectedUser(selectedUser);
    props.selectUser(selectedUser);
  };



  const uniqueList = useMemo(
    () =>
      connectedUsers
        .filter(
          (obj, index, self) => index === self.findIndex((o) => o.sessionID === obj.sessionID)
        )
        .filter((user) => user.isActiveChat)
        .filter((user) => user.adminAssigned === userAttributes.name),
    [connectedUsers]
  );




  localStorage.setItem('onlineUsers', JSON.stringify(uniqueList));


  const showUsers = uniqueList.map((user) => {
    const unreadCount = unreadCounts[user.sessionID] || null;
    const isSelected = selectedUser && selectedUser.username === user.username;

    return (
      <div
        key={user.key}
        className={`${styles['main-sidebar-content-cnt']} ${isSelected ? styles.selected : styles.notSelected
          }`}
        onClick={() => {
          readMessages(user.sessionID);
          userName_from_click(user);
        }}
      >
        <div className={styles['sidebar-cnt-tp']}>
          <div className={styles['online-icon']}>
            <GoPrimitiveDot className={styles['online-icon-dot']} />
            <span style={{ fontWeight: 500 }}>{user.username}</span>
          </div>
          <span
            style={{
              display: 'inline-block',
              backgroundColor: 'rgba(34, 197, 94, 0.16)',
              padding: '2px 8px',
              borderRadius: '8px',
              fontSize: '11px',
              fontWeight: '400',
              color: 'rgb(17, 141, 87)',
            }}
            className={styles['sidebar-top-right']}
          >
            Active
          </span>
        </div>
        <div className={styles['sidebar-cnt-tp']}>
          <div className={styles['online-icon']}>
            <ReactCountryFlag countryCode={user.country_code2} svg />
            <span className={styles['sidebar-cnt-dn-cntry']}>{user.country_name}</span>
          </div>
          {(isTyping.includes(user.sessionID) && !isSelected) ? (
            <div className={styles['typing-message']}>
              <span style={{ opacity: '0.7', fontWeight: '400', fontSize: '10px', marginRight: '2px' }}>
                User is typing
              </span>
              <span style={{ opacity: '0.8', marginRight: '10px' }} className={styles['typing-message-content']}></span>
            </div>
          ) : (
            (unreadCount !== null && <span className={styles['unread-count']}>{unreadCount}</span>)
          )}
        </div>
      </div>
    );
  });

  if (uniqueList.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px',
          alignItems: 'center',
          height: '63vh', // Adjust this to control the height of the container
        }}
      >
        No users in active chat
      </div>
    );
  }

  return <div style={{ marginBottom: '15%' }}>{showUsers}</div>;
};

export default ActiveChat;
