import styles from "./signupTopNav.module.css"
import { useEffect, useState, useRef } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { BsBellFill, BsSearch } from "react-icons/bs";
import comcat from "../../Img/comcatLogoOrange.png"
import comcatTextDark from "../../Img/comcatTextDark.png"


const SignupTopNav = () => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();


  return (

    <div className={styles['main-navbar']}>


      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={logoutInProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      {/* <div> */}
        <div className={styles.search_icon}>
          <img src={comcat} alt="logo" width="37px" height="37px" />
          <img src={comcatTextDark} alt="" style={{width:"75px", marginLeft:"10px"}} />
        </div>
      {/* </div> */}

      <div className={styles['nav-profile-opt']}>
        <div className={styles['nav-profile-opt-right']} >

          <div className={styles.upgrade_btn_container} onClick={() => navigate("/login")}>
            <button className={styles.upgrade_btn}>Login</button>
          </div>
        </div>
      </div>
    </div >
  )
}
export default SignupTopNav