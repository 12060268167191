import { Alert, Snackbar } from "@mui/material";

export default function ErrorSnackbar({isErrorSnackbarOpen, setIsErrorSnackbarOpen}){

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpen({ isOpen: false, message: "" });
    };
     
    return (
        <Snackbar
        open={isErrorSnackbarOpen.isOpen}
        autoHideDuration={10000} // Adjust the duration as needed
        onClose={handleErrorSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
        <Alert
            onClose={handleErrorSnackbarClose}

            severity="error"
            sx={{ width: '100%', fontWeight: "400", boxShadow: "0 0 10px rgb(83 83 83 / 35%)" }}

        >
            {isErrorSnackbarOpen.message}
        </Alert>
    </Snackbar>
    )
}