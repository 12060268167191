// Signup.jsx
import React, { useState, useEffect } from 'react';
import styles from './forceReset.module.css'; // Import your CSS module
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import comcatTextlogo from "../../Img/comcatTextlogo.png"
// import { Auth } from 'aws-amplify';
// import { createUser } from '../../Service/API.js';
import ErrorSnackbar from '../../Components/ErrorSnackbar/ErrorSnackbar.js';
import FormComponent from './ForceReset_components/FormComponent.js';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SuccessSnackbar from '../../Components/SuccessSnackbar/SuccessSnackbar.js';



function ForceReset() {
    const navigate = useNavigate()
    const location = useLocation();
    const oldPassword = location.state?.password;
    const username = location.state?.username;
    const [activeStep, setActiveStep] = useState(0);
    const [formFields, setFormFields] = useState({
        username,
        oldPassword,
        newPassword: '',
        confirmPassword: ''

    });
    const [open, setOpen] = React.useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [errorAlert, setErrorAlert] = useState({ isOpen: false, message: "" })
    const [successSnackbar, setSuccessSnackbar] = useState({isOpen:false, message:""})


    console.log("oldPassword=====>",oldPassword)


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                const message = 'You have unsaved changes. Are you sure you want to leave?';
                event.returnValue = message; // Standard for most browsers
                return message; // For some older browsers
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);


    const handleFieldChange = (field, value) => {
        setUnsavedChanges(true);
        setFormFields((prevFields) => ({
            ...prevFields,
            [field]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (formFields.newPassword !== formFields.confirmPassword) {
            setErrorAlert({ isOpen: true, message: "Kindly make sure new and confirm passwords are same." })
            return
        }
        if (passwordStrength !== 1) {
            setErrorAlert({ isOpen: true, message: "Kindly make sure you have entered a secure password." })
            return
        }

        try {
            setOpen(true)
            const response = await axios.post('http://5.161.184.34:4200/change-password', {
                username: formFields.username,
                oldPassword: formFields.oldPassword,
                newPassword: formFields.confirmPassword,
            });
            setOpen(false)
            console.log("Password reseted succussfully...")
            setSuccessSnackbar({isOpen:true, message:"Password successfully reset..."})

            setTimeout(()=>navigate("/login"),3000)

            
        } catch (error) {
            setOpen(false)
            console.log("Error in reseting password : ", error.response)
            setErrorAlert({isOpen:true, message:"Something went wrong in reseting password."})
        }

        console.log("password strength : ", passwordStrength)
    }

    return (
        <div className={styles.Signup_wrapper}>


            <div className={styles.Signup}>
                <div className={styles['policy-terms-container']}>
                    <p>Terms of Use</p>
                    <span> | </span>
                    <p>Privacy Policy</p>
                </div>

                <div>
                    <img className={styles['logo-stampa']} src={comcatTextlogo} alt="Logo" />
                </div>
                <div className={styles['heading-text-style']}>
                    <span className={styles['Create-account']}>Reset Your Password</span>
                    {/* <p className={styles['Register-account']}>Register an account, Come and join us</p> */}
                </div>

                {/* <div className={styles.Stepper}>
          <StepperComponent activeStep={activeStep} steps={steps} />
        </div> */}


                <form className={styles['form-signup']} onSubmit={handleSubmit}>
                    <FormComponent
                        formFields={formFields}
                        handleFieldChange={handleFieldChange}
                        passwordStrength={passwordStrength}
                        setPasswordStrength={setPasswordStrength}
                    />
                    <div className={styles['button-signup-container']}>

                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth={activeStep === 0 ? true : undefined}
                            size="large"
                            style={{ backgroundColor: 'rgb(246, 126, 78)', flex: '1' }}
                        >
                            Reset
                        </Button>
                    </div>
                </form>



            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <ErrorSnackbar isErrorSnackbarOpen={errorAlert} setIsErrorSnackbarOpen={setErrorAlert} />
            <SuccessSnackbar isSnackbarOpen={successSnackbar} setIsSnackbarOpen={setSuccessSnackbar} />
        </div>

    );
}

export default ForceReset;
