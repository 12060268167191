

import React, { useState, useEffect } from 'react';
import styles from "./dashboard.module.css"
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import ForumIcon from '@mui/icons-material/Forum';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import WelcomeCard from './Dashboard_Components/WelcomeCard';
import DashboardCard from './Dashboard_Components/DashboardCard/DashboardCard.js';
import { HiSignal } from "react-icons/hi2";
import MenuSideBar from '../../Components/MenuSidebar/MenuSideBar.js';
import Navbar from '../../Components/Navbar/Navbar.js';
import { useUser } from "../../Service/SelectedUserContext.js";
import BarChart from './Dashboard_Components/BarChart/BarChart.js';
import PieChart from './Dashboard_Components/PieChart/PieChart.js';
import BasicTable from './Dashboard_Components/BasicTable/BasicTable.js';
import DoughnutChart from './Dashboard_Components/DoughnutChart/DoughnutChart.js';
import CountriesChart from './Dashboard_Components/CountriesChart/CountriesChart.js';
import { ActiveChatsIcon } from '../../Components/MenuSidebar/Icons/ActiveChatsIcon.js';
import { useConnectedUsersContext } from '../../Service/ConnectedUsersContext.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function Dashboard({CurrentuserAuth }) {

  const [lineChartFilter, setLineChartFilter] = useState(7)
  const [barChartFilter, setBarChartFilter] = useState(new Date().getFullYear())
  const { disconnectedUsers,connectedUsers } = useConnectedUsersContext();
  const years = []
  for (let year = 2000; year <= new Date().getFullYear(); year++) {
    years.push(year);
  }


  const pieChart = [
    { id: 1, country_name: "United States" },
    { id: 2, country_name: 'Canada' },
    { id: 3, country_name: 'Pakistan' },
    { id: 4, country_name: 'United Kingdom' },
    { id: 5, country_name: 'Canada' },
    // Add more user data as needed
  ];

  const { selectedUser, setSelectedUser } = useUser();
  function getCurrentDate() {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const today = new Date();
    const dayOfWeek = daysOfWeek[today.getDay()];
    const day = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    return `${dayOfWeek} ${day} ${month} ${year}`;
  }

  const currentDateWelcome = getCurrentDate();

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  };



  useEffect(() => {
    setSelectedUser(null)
  }, []);



  const handleClick = async () => {
    console.log("Testing")
  };

  let showUsers = disconnectedUsers
    .filter(user => user.isConversation === true)

  console.log("show users : ", showUsers)

  const totalChats = showUsers.length;
  const totalVisitors = disconnectedUsers.length;

  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  const daysUntilMonday = (currentDayOfWeek + 6) % 7;
  const currentWeekStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - daysUntilMonday);

  // Calculate the start of the previous week
  const previousWeekStart = new Date(currentWeekStart);
  previousWeekStart.setDate(previousWeekStart.getDate() - 7);

  // Parse the date strings in the array for this week and last week
  const parseDateString = (dateString) => {
    const [day, dayNumber, month, year, time] = dateString.split(' ');
    const [hours, minutes] = time.split(':');
    const monthNumber = new Date(Date.parse(month + ' 1, ' + year)).getMonth(); // Convert month name to month number
    return new Date(year, monthNumber, dayNumber, hours, minutes);
  };

  // Filter connections for this week and last week, and parse the date strings
  const thisWeekConnectionsVisitors = disconnectedUsers.filter((user) => {
    const userDate = parseDateString(user.connectionTime);
    return userDate >= currentWeekStart;
  });

  const lastWeekConnectionsVisitors = disconnectedUsers.filter((user) => {
    const userDate = parseDateString(user.connectionTime);
    return userDate >= previousWeekStart && userDate < currentWeekStart;
  });

  const thisWeekConnectionsChat = showUsers.filter((user) => {
    const userDate = parseDateString(user.connectionTime);
    return userDate >= currentWeekStart;
  });

  const lastWeekConnectionsChat = showUsers.filter((user) => {
    const userDate = parseDateString(user.connectionTime);
    return userDate >= previousWeekStart && userDate < currentWeekStart;
  });

  // Count the number of connections for this week and last week
  const thisWeekConnectionCountVisitors = thisWeekConnectionsVisitors.length;
  const lastWeekConnectionCountVisitors = lastWeekConnectionsVisitors.length;

  const thisWeekConnectionCountChat = thisWeekConnectionsChat.length;
  const lastWeekConnectionCountChat = lastWeekConnectionsChat.length;

  // Calculate the percentage change
  const percentageChangeVisitors = ((thisWeekConnectionCountVisitors - lastWeekConnectionCountVisitors) / lastWeekConnectionCountVisitors) * 100;
  const percentageChangeChat = ((thisWeekConnectionCountChat - lastWeekConnectionCountChat) / lastWeekConnectionCountChat) * 100;

  const roundedPercentageChangeVisitors = Math.round(percentageChangeVisitors);
  const roundedPercentageChangeChat = Math.round(percentageChangeChat);

  const today = new Date();
  const last30Days = Array.from({ length: lineChartFilter }, (_, index) => {
    const date = new Date(today);
    date.setDate(today.getDate() - index);
    return formatDate(date); // Format date as "DD MMM" (e.g., "23 Oct")
  }).reverse(); // Reverse the order of dates

  console.log("last 30 days : ", last30Days)

  // Process the showUsers data to count users connected on each day
  const userCountByDay = {};

  showUsers.forEach((user) => {
    const date = new Date(user.connectionTime);
    const dateString = formatDate(date); // Format date as "DD MMM"

    if (userCountByDay[dateString]) {
      userCountByDay[dateString]++;
    } else {
      userCountByDay[dateString] = 1;
    }
  });

  // Fill in user counts for all the last 30 days
  const userCounts = last30Days.map((day) => userCountByDay[day] || 0);

  console.log("User Counts : ", userCounts)

  // Separate the labels (days) and data (user counts) for the chart
  const highestValue = Math.max(...userCounts);

  const data = {
    labels: last30Days,

    datasets: [
      {
        label: "Chats",
        data: userCounts,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 7,
        tension: 0.3
      },
    ]
  };

  const chartOptions = {
    responsive: true, // Make the chart responsive

    maintainAspectRatio: false, // This property ensures the chart takes up all available width
    scales: {
      x: {

        grid: {
          display: false, // Disable x-axis grid lines
          borderDash: [8, 4],

        },

        ticks: {
          stepSize: 4,
          maxRotation: 0, // Set the rotation to 0 degrees for horizontal labels
          minRotation: 0, // Set the rotation to 0 degrees for horizontal labels
          autoSkipPadding: 4,
          color: '#909eab',
        },
      },
      y: {

        border: { dash: [4, 4] },
        grid: {
          color: "rgb(231 231 231)",
          borderDash: [8, 4],
        },

        beginAtZero: true, // Start the y-axis at 0
        max: highestValue + 1, // Set the maximum value of the y-axis to 10
        ticks: {
          stepSize: 1, // Set the step size to 1 to display integer values
          min: 0,
          max: 6,
          color: 'grey',
        },
      },

    },
    plugins: {
      legend: {
        display: false, // Hide the legend (square with label)

      },
    },

  };

  return (
    <>
      <MenuSideBar
        page={"Dashboard"}
        handleClick={handleClick}
        ShowIndicatior={true}
      />
      <div className='main-mychat'>
        <Navbar />
        <div style={{ display: 'flex', overflowY: 'auto' }}>
          <div className={styles["main-content-reports"]}>
            <div className={styles["dashboard-area"]}>
              <div className={styles["dashboard-title-div"]}>
              <p className={styles["dashboard-welcome"]}>
        Welcome, <span className={styles.name}>{CurrentuserAuth.name.charAt(0).toUpperCase() + CurrentuserAuth.name.slice(1)}</span>
      </p>
                <p className={styles["dashboard-text"]}>Enhance Customer Experience with Live Support</p>
              </div>

              <div className={styles["dashboard-cards-area"]}>
                <div style={{ flex: "1" }}>
                  <DashboardCard title="Total Visitors" gradientColors="linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))" icon={<PeopleAltIcon style={{ fontSize: '30px' }} />} total={totalVisitors} percentageChange={roundedPercentageChangeVisitors} thisWeekCount={thisWeekConnectionCountVisitors} />
                </div>
                <div style={{ flex: "1" }}>
                  <DashboardCard title="Total Chats" gradientColors="linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))" icon={<ActiveChatsIcon fill="white" width="30px" height="30px" />} total={totalChats} percentageChange={roundedPercentageChangeChat} thisWeekCount={thisWeekConnectionCountChat} />
                </div>
                <div style={{ flex: "1" }}>
                  <DashboardCard title="Total Live Visitors" gradientColors="linear-gradient(195deg, rgb(246 126 78), rgb(254 122 70))" icon={<HiSignal style={{ fontSize: '30px' }} />} total={connectedUsers.length} />
                </div>
              </div>
              {/* <WelcomeCard currentDateWelcome={currentDateWelcome} CurrentuserAuth={CurrentuserAuth} /> */}

              <div className={styles.outerContainer}>
                <div className={styles.componentSpecialDiv} style={{ flex: "3" }}>
                  <div className={styles["disconnected-user-card"]}>

                    <div className={styles["disconnected-user-card-content"]}>
                      <div className={styles.filters_container}>
                        <div>
                          <h1 className={styles.graph_title}>Chats</h1>
                        </div>
                        <div className={styles.line_graph_filter_container}>
                          <select className={styles.line_graph_filter} value={lineChartFilter} onChange={(e) => setLineChartFilter(Number(e.target.value))}>
                            <option value="7">7 days</option>
                            <option value="15">15 days</option>
                            <option value="30">30 days</option>
                            <option value="90">90 days</option>
                          </select>
                        </div>
                      </div>

                      <Line data={data} options={chartOptions} />
                    </div>
                  </div>
                </div>
                <div className={styles.componentSpecialDiv}>
                  <div className={styles["disconnected-user-card"]}>

                    {/* <div className={styles.table_container}>
                      <BasicTable connectedUsersList={showUsers} />
                    </div> */}

                    <div className={styles["disconnected-user-card-content"]}>
                      <p className={styles.countries_chart_title}>Session by Country</p>
                      <p style={{ fontSize: '16px', color: '#a2a5a9' }}>Showing Data for Top Session</p>
                      <div className={styles.pieChartContainer}>
                        {/* <ApexChart connectedUsersList={showUsers} /> */}
                        {/* <PieChart data={pieChart} /> */}
                        <div style={{ width: "280px", height: "280px" }}>
                          <CountriesChart disconnectedUsers={disconnectedUsers} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.outerContainer}>
                <div className={styles.componentSpecialDiv} style={{ flex: 3 }}>
                  <div className={styles["disconnected-user-card"]}>

                    <div className={styles["disconnected-user-card-content"]}>
                      <div className={styles.filters_container}>
                        <div>
                          <h1 className={styles.graph_title}>Yearly Chat Analysis</h1>
                        </div>
                        <div className={styles.line_graph_filter_container}>
                          <select className={styles.line_graph_filter} value={barChartFilter} onChange={(e) => setBarChartFilter(Number(e.target.value))}>
                            {years.map(year=>{
                              return <option key={year} value={year}>{year}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <BarChart barChartFilter={barChartFilter} disconnectedUsers={disconnectedUsers} />
                    </div>
                  </div>
                </div>

                <div className={styles.componentSpecialDiv}>
                  <div className={styles["disconnected-user-card"]}>
                    <div className={styles["disconnected-user-card-content"]}>
                      <h2>Chats</h2>
                      <div className={styles.pieChartContainer}>
                        {/* <ApexChart connectedUsersList={showUsers} /> */}
                        <div style={{ width: "280px", height: "280px" }}>
                          <DoughnutChart />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* complete row */}
              {/* <div className={styles.outerContainer}>
                <div className={styles.componentSpecialDiv}>
                  <div className={styles["disconnected-user-card"]}>

                    <div className={styles.table_container}>
                      <BasicTable connectedUsersList={showUsers} />
                    </div>

                    <div className={styles["disconnected-user-card-content"]}>
                      <h2>Chats</h2>
                      <div className={styles.pieChartContainer}>
                        <div style={{ width: "280px", height: "280px" }}>
                          <PieChart data={pieChart} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </div>

        </div>
      </div>
    </>
  );

}
