// Routes.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../Pages/Login/Login';
import Signup from '../Pages/Signup/Signup';
import BotFlow from '../Pages/BotFlow/BotFlow';
import { Live } from '../Pages/Live/Live';
import Main from '../Pages/Main/Main';
import Dashboard from '../Pages/Dashboard/Dashboard';
import { useAuth } from '../Service/AuthContext';
import { Settings } from "../Pages/Settings/Settings"
import ForceReset from '../Pages/ForceReset/ForceReset';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import { useConnectedUsersContext } from '../Service/ConnectedUsersContext';

const RedirectToLogin = ({ fetchData, userAttributes, element }) => {
    const [redirectElement, setRedirectElement] = useState(null);

    useEffect(() => {
        const fetchUserAttributes = async () => {
            if (!userAttributes) {
                console.log("Fetching userAttributes");
                if (localStorage.getItem('session-userID-admin')) {
                    try {
                        console.log("Waiting for fetching data");
                        await fetchData();
                    } catch (error) {
                        console.error('Error fetching data:', error);
                        setRedirectElement(<Navigate to="/login" />);
                    }
                } else {
                    console.log('Error fetching data:');
                    setRedirectElement(<Navigate to="/login" />);
                }
            } else {
                console.log("UserAttributes already available");
            }
        };

        fetchUserAttributes();
    }, [userAttributes]);

    useEffect(() => {
        if (userAttributes) {
            console.log("userAttributes available, setting redirectElement");
            setRedirectElement(element);
        }
    }, [userAttributes, element]);

    return redirectElement;
};


const AppRoutes = ({ userName, createSession, currentUserInfo, fetchData }) => {
    const { userAttributes } = useAuth();
    const { connectedUsers } = useConnectedUsersContext();

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/login" element={<Login submit={(event, attributes) => createSession(event.toLowerCase(), attributes)} />} />

            <Route
                path="/dashboard"
                element={
                    <RedirectToLogin
                        fetchData={fetchData}
                        userAttributes={userAttributes}
                        element={<Dashboard CurrentuserAuth={userAttributes}/>}
                    />
                }
            />

            <Route
                path="/liveview"
                element={
                    <RedirectToLogin fetchData={fetchData} userAttributes={userAttributes} element={<Live connectedUsersList={connectedUsers} />} />
                }
            />

            <Route path="/BotFlow" element={<RedirectToLogin fetchData={fetchData} userAttributes={userAttributes} element={<BotFlow />} />} />

            <Route path="/settings/*" element={<RedirectToLogin fetchData={fetchData} userAttributes={userAttributes} element={<Settings />} />} />

            <Route path="/signup" element={<Signup submit={(event, attributes) => createSession(event.toLowerCase(), attributes)} />} />

            <Route path="/ForceReset" element={<ForceReset />} />
            <Route path='/ForgotPassword' element={<ForgotPassword />} />

            <Route
                path={`/history/:chatType?/:sessionID?`}
                element={
                    <RedirectToLogin
                        fetchData={fetchData}
                        userAttributes={userAttributes}
                        element={<Main
                            userName={userName}
                            CurrentusersocketID={currentUserInfo.socketID}
                            CurrentuserAuth={currentUserInfo.auth}
                            CurrentusersessionID={currentUserInfo.sessionID}
                            connectedUsers={connectedUsers}
                            page={"history"}
                            
                        />}
                    />
                }
            />


            {["visitor", "ActiveChat"].map((page) => {
                const pageName = page.split('/')[0];
                const routeElement = (
                    <RedirectToLogin
                        fetchData={fetchData}
                        userAttributes={userAttributes}
                        element={
                            <Main
                                userName={userName}
                                CurrentusersocketID={currentUserInfo.socketID}
                                CurrentuserAuth={currentUserInfo.auth}
                                CurrentusersessionID={currentUserInfo.sessionID}
        
                                connectedUsers={connectedUsers}
                                page={pageName}
                            />
                        }
                    />
                );

                return (
                    <Route
                        key={page}
                        path={`/${pageName}/:sessionID?`}
                        element={routeElement}
                    />
                );
            })}


        </Routes>
    );
};





export default AppRoutes;
