import React, { createContext, useState, useContext } from 'react';

const GlobalVariablesContext = createContext();

export const GlobalVariablesProvider = ({ children }) => {
  const [showIndicatorNewUser, setShowIndicatorNewUser] = useState(false);
  const [showIndicatorNewMessage, setShowIndicatorNewMessage] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);

  const toggleIndicatorNewUser = () => {
    setShowIndicatorNewUser(false);
  };

  const toggleIndicatorNewMessage = () => {
    setShowIndicatorNewMessage(false);
  };



  const setGlobalProfilePicture = (imageUrl) => {
    setProfilePicture(imageUrl);
  };



  return (
    <GlobalVariablesContext.Provider value={{ toggleIndicatorNewUser,toggleIndicatorNewMessage,showIndicatorNewUser, setShowIndicatorNewUser,showIndicatorNewMessage,setShowIndicatorNewMessage,setGlobalProfilePicture,profilePicture }}>
      {children}
    </GlobalVariablesContext.Provider>
  );
};

export const useGlobalVariablesContext = () => {
  return useContext(GlobalVariablesContext);
};
