import styles from "../forgotPassword.module.css"
import { MdFingerprint } from "react-icons/md";
import Button from '@mui/material/Button';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';



export default function EmailComponent({email,setEmail, handleEmailSend, emailError, loading}) {
    const navigate = useNavigate()


    return (
        <div className={styles.email_container}>
            <div className={styles.top_section}>
                <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <MdFingerprint style={{ width: "20px", height: "20px" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p}>Forgot Password?</p>
                    <p style={{ color: "#8E8EA0", fontSize: "17px", textAlign: "center" }}>No worries, we'll send you reset instructions.</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container}>
                    <label htmlFor="" style={{ fontSize: "15px" }}>Email</label>
                    <input className={styles.input_field} placeholder="Enter your email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {emailError && <span style={{color:"red", fontSize:"12px"}}>{emailError}</span>}
                </div>
                <div>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={handleEmailSend} variant="contained" fullWidth>Send</Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}