import { Doughnut } from 'react-chartjs-2';
import colorbrewer from 'colorbrewer';


export default function DoughnutChart() {
  const dummyData = [
    {
      operatingsystem: "Windows 10",
    },
    {
      operatingsystem: "Mac OS X 10.15.7"
    }, 
    {
      operatingsystem: "Windows 10",
    },
    {
      operatingsystem: "Android OS X 10.15.7"
    },
    {
      operatingsystem: "Windows 10",
    },
    {
      operatingsystem: "IOS OS X 10.15.7"
    },
    {
      operatingsystem: "Android OS X 10.15.7"
    }
  ]
  const operatingSystemLabels = [...new Set(dummyData.map(user => user.operatingsystem.split(" ")[0]))];
  const operatingSystemCounts = operatingSystemLabels.map(label =>
    dummyData.filter(user => user.operatingsystem.split(" ")[0] === label).length
  );

  // console.log("Systems : ", countryLabels, "Counts : ", countryCounts)

  const dataObject = {"Desktop":0, "Phone":0}
  operatingSystemLabels.forEach((label,index)=>{
    if(label==="Windows" || label==="Mac"){
      dataObject['Desktop'] += operatingSystemCounts[index]
    }
    else{
      dataObject['Phone'] += operatingSystemCounts[index]
    }
})


  const colorPalette = colorbrewer.Set3[Object.keys(dataObject)];

  const data = {
    labels: Object.keys(dataObject),
    datasets: [
      {
        label: 'Chats',
        data: Object.values(dataObject),
        backgroundColor: ["#f37440", "#182e42"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Doughnut data={data} />
  )
}