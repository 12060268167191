import { useState } from "react";
import { BiLinkAlt } from "react-icons/bi";
import { BsChevronDoubleDown, BsChevronDoubleUp, BsGeoAlt, BsPersonFill, BsPhone, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { MdDone, MdModeEdit, MdOutlineAlternateEmail } from "react-icons/md";
import styles from "./rightInfoSidebar.module.css"
import chrome from '../../../../Img/chrome.png';
import safari from '../../../../Img/safari.png';
import microsoft from '../../../../Img/microsoft.png';
import firefox from '../../../../Img/firefox.png';
import macLogo from '../../../../Img/macLogo.png';
import windows from '../../../../Img/windows.png';
import android from '../../../../Img/android.png';
import mobileLogo from '../../../../Img/mobileLogo.png';
import ActivityInfo from "../ActivityInfo/ActivityInfo"

export default function RightInfoSidebar({ userData, selectedUserSessionId, IsLoadingSidebar, handleUsernameChange, updateUsername, edt, setEdt, isValidEmail, handleEmailChange, handleContactChange }) {
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [active, setActive] = useState(true);
    const [visitorVisible, setVisitorVisible] = useState(true);
    const [activitiesVisible, setActivitiesVisible] = useState(true);


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };
    const handleActive = () => {
        setActive(true);
    };

    const handleNonActive = () => {
        setActive(false);
    };

    const handleEdit = async () => {
        setEdt(!edt);
    }
    const toggleVisitor = () => {
        setVisitorVisible(!visitorVisible);
    };

    const getBrowserIcon = (browser) => {
        if (browser.includes('Chrome')) {
            return <img src={chrome} className={styles['browser-icon']} alt="Chrome" />;
        } else if (browser.includes('Edge')) {
            return <img src={microsoft} className={styles['browser-icon']} alt="Edge" />;
        } else if (browser.includes('Safari')) {
            return <img src={safari} className={styles['browser-icon']} alt="Safari" />;
        } else if (browser.includes('Firefox')) {
            return <img src={firefox} className={styles['browser-icon']} alt="Firefox" />;
        } else {
            return <img src={chrome} className={styles['browser-icon']} alt="Chrome" />; // You can use any other icon for unknown browsers
        }
    };

    const getOSIcon = (OS) => {
        if (OS.includes('Mac')) {
            return <img src={macLogo} className={styles['browser-icon']} alt="Mac" />;
        } else if (OS.includes('Windows')) {
            return <img src={windows} className={styles['browser-icon']} alt="Windows" />;
        } else if (OS.includes('Android')) {
            // If the OS is Android, return two identical images (for illustration purposes).
            return (
                <>
                    <img src={android} className={styles['browser-icon']} alt="Android" />
                    <img src={mobileLogo} className={styles['browser-icon']} alt="mobileLogo" />
                </>
            );
        } else {
            return <img src={macLogo} className={styles['browser-icon']} alt="Mac" />; // You can use any other icon for unknown browsers
        }
    };
    const toggleActivities = () => {
        setActivitiesVisible(!activitiesVisible);
    };

    const extractPath = (url) => {
        try {
            const path = new URL(url).pathname;
            return path !== '/' ? path : url;
        } catch (error) {
            console.error('Error extracting path:', error);
            return url;
        }
    };

    const formatLink = (visitlink) => {
        console.log("visitlink......",visitlink)
        const url = new URL(visitlink);

        if (url.pathname === '/') {
            return url.href; // Show full URL if path is '/'
        } else {
            const pathSegments = url.pathname.split('/').filter(Boolean);
            if (pathSegments.length > 0) {
                return `/${pathSegments[pathSegments.length - 1]}`;
            } else {
                return url.href; // Show full URL if there are no path segments
            }
        }
    };


    return (
        <div className={styles['side-bar']} style={{ zIndex: "111" }}>
            <div className={sidebarVisible ? styles.openIcon : styles.openCloseIcon} onClick={toggleSidebar}>
                {
                    sidebarVisible ?
                        <BsChevronDoubleLeft ></BsChevronDoubleLeft>
                        :
                        <BsChevronDoubleRight />
                }
            </div>
            <div className={sidebarVisible ? styles["right-sidebar-close"] : styles["right-sidebar-open"]}>
                {!sidebarVisible && (
                    <div className={styles['toggle-btn-visitor']}>
                        <span
                            className={active ? styles['active-visitor'] : styles['non-active-visitor']}
                            onClick={handleActive}
                        >
                            Info
                        </span>
                        <span
                            className={!active ? styles['active-visitor'] : styles['non-active-visitor']}
                            onClick={handleNonActive}
                        >
                            Activity
                        </span>
                    </div>
                )}


                {!active ? (
                    <ActivityInfo userData={userData} selecteduser={selectedUserSessionId} />
                ) : (

                    <>


                        {IsLoadingSidebar ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '63vh', marginTop: "25px" }}>

                                <div className={styles.loader}></div>
                            </div>
                        ) : (
                            <>
                                {!sidebarVisible && (
                                    userData.map((pdata) => {
                                        const minutes = Math.floor(pdata.timespend);
                                        const seconds = Math.floor((pdata.timespend - minutes) * 60);

                                        let formattedTime = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
                                        if (seconds > 0) {
                                            formattedTime += ` and ${seconds} second${seconds !== 1 ? 's' : ''}`;
                                        }
                                        return (
                                            <div key={pdata.sessionID}>
                                                {/* Profile Section */}
                                                <div className={styles["profile-section"]}>

                                                    <div className={styles["profile-info"]}>

                                                        <BsPersonFill className={styles['profile-info-svg']} />

                                                        <div style={{ width: "90%" }} className='row'>
                                                            <div className='row '>
                                                                {
                                                                    edt ? (
                                                                        <>
                                                                            <input
                                                                                type="text"
                                                                                className={`form-control ${styles['username-info-enable-input']} ms-3 `}
                                                                                name={`pdata.username.${pdata.sessionID}`}
                                                                                value={pdata.username}
                                                                                onChange={(e) => handleUsernameChange(pdata.sessionID, e.target.value)}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <input
                                                                                className={styles['contact-info-disable-input']}
                                                                                value={pdata.username ? pdata.username : 'N/A'}
                                                                                disabled
                                                                            />
                                                                        </>
                                                                    )
                                                                }

                                                            </div>

                                                            <div className='row'>
                                                                <h2 style={{ fontSize: "11px", marginTop: 4 }} >{pdata.country_name ? pdata.country_name : "-"}</h2>
                                                            </div>
                                                        </div>
                                                        {edt ?
                                                            <div className={styles['edit-icon']} onClick={() => updateUsername(pdata.username, pdata.email, pdata.ContactNumber)}><MdDone className={styles.edit} /></div>
                                                            :
                                                            <div className={styles['edit-icon']} onClick={handleEdit}><MdModeEdit className={styles.edit} /></div>
                                                        }
                                                    </div>
                                                    <div className={styles["contact-info"]}>
                                                        <div >
                                                            {
                                                                edt ?
                                                                    <>
                                                                        <MdOutlineAlternateEmail size={18} style={{ marginRight: 10 }} />
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${styles['contact-info-enable-input']} ${!isValidEmail ? 'is-invalid' : ''}`}
                                                                            style={!isValidEmail ? { borderColor: 'red' } : {}}
                                                                            name={`pdata.email.${pdata.sessionID}`}
                                                                            value={pdata.email}
                                                                            onChange={(e) => handleEmailChange(pdata.sessionID, e.target.value)}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <MdOutlineAlternateEmail size={18} style={{ marginRight: 10 }} />
                                                                        <input className={styles['contact-info-disable-input']} value={pdata.email ? pdata.email : "N/A"} disabled />
                                                                    </>
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                edt ?
                                                                    <>
                                                                        <BsPhone size={18} style={{ marginRight: 10 }} />

                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${styles['contact-info-enable-input']}`}
                                                                            name={`pdata.ContactNumber.${pdata.sessionID}`}
                                                                            value={pdata.ContactNumber}
                                                                            onChange={(e) => handleContactChange(pdata.sessionID, e.target.value)}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <BsPhone size={18} style={{ marginRight: 10 }} />
                                                                        <input className={styles['contact-info-disable-input']} value={pdata.ContactNumber ? pdata.ContactNumber : "N/A"} disabled />
                                                                    </>
                                                            }
                                                        </div>
                                                        <div>
                                                            {
                                                                <>
                                                                    <BsGeoAlt size={18} style={{ marginRight: 10 }} />
                                                                    <input className={styles['contact-info-disable-input']} value={pdata.country_name ? pdata.country_name : "N/A"} disabled />
                                                                </>
                                                            }

                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <BiLinkAlt size={18} style={{ marginRight: 10 }} />
                                                            {pdata.activityURl && pdata.activityURl.length > 0 ? (
                                                                <a
                                                                    href={pdata.activityURl[pdata.activityURl.length - 1].visitlink}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {pdata.activityURl[pdata.activityURl.length - 1].visitlink}
                                                                </a>
                                                            ) : (
                                                                <span>N/A</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                                {/* Divider */}
                                                <hr style={{ color: "grey", margin: '0px 0' }} />
                                                {/* Visitor Information Section */}
                                                <div className={styles["visitor-section"]}>
                                                    <p style={{ fontSize: "14px", color: "#223952" }} onClick={toggleVisitor}>
                                                        Visitor Information
                                                        <span className={styles["arrow"]}> {visitorVisible ? <BsChevronDoubleUp /> : <BsChevronDoubleDown />}</span>
                                                    </p>
                                                    <div className={visitorVisible ? styles.animationshow : styles['animation-dropdown']}>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>First Visit</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>{pdata.connectionTime ? pdata.connectionTime : "-"}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Last Visit</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>{pdata.disconnectTime ? pdata.disconnectTime : "-"} </p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Number of Visits</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p style={{ fontWeight: "600" }} className={styles['visitor-Information-text']}> {pdata.VisitCounter ? pdata.VisitCounter : "-"} </p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Browser</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p style={{ justifyContent: "start" }} className={styles['visitor-Information-text']}>
                                                                    {pdata.browser && (
                                                                        <>
                                                                            {getBrowserIcon(pdata.browser)}
                                                                            <span>{pdata.browser}</span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Operating System</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p style={{ justifyContent: "start" }} className={styles['visitor-Information-text']}>
                                                                    {pdata.operatingsystem && (
                                                                        <>
                                                                            {getOSIcon(pdata.operatingsystem)}
                                                                            <span>{pdata.operatingsystem}</span>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>First Visit Source</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>
                                                                    {pdata.referrerUrl ? (
                                                                        <a href={pdata.referrerUrl}>{pdata.referrerUrl}</a>
                                                                    ) : (
                                                                        <strong style={{ fontWeight: 'bold' }}>Direct</strong>
                                                                    )}
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Referral Link</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>{pdata.refferallink ? pdata.refferallink : "-"} </p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Avg. time spent</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>{pdata.timespend ? formattedTime : "-"}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>IP Address</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p style={{ fontWeight: "600" }} className={styles['visitor-Information-text']}>{pdata.ip ? pdata.ip : "-"}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}>Pin Address</p>
                                                            </div>
                                                            <div className='col'>
                                                                <p className={styles['visitor-Information-text']}> {pdata.country_name ? pdata.country_name : "-"} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Divider */}
                                                {/* ActivitiesSection */}
                                                <div className={styles["visitor-section"]}>
                                                    <p style={{ fontSize: "14px", color: "#223952" }} onClick={toggleActivities}>
                                                        Activities
                                                        <span className={styles["arrow"]}>{activitiesVisible ? <BsChevronDoubleUp /> : <BsChevronDoubleDown />} </span>
                                                    </p>
                                                    {/* Activities information details here */}
                                                    <div className={activitiesVisible ? styles.animationshow : styles['animation-dropdown']}>
                                                        <div className={styles["timeline-container"]}>
                                                            <ul className={styles.tl}>
                                                                {pdata.activityURl.slice().reverse().map((ua) => (
                                                                    <li key={ua.id}>
                                                                        <div className={styles["item-icon"]}></div>
                                                                        <div className={styles["item-text"]}>
                                                                            <a
                                                                                href={ua.visitlink}
                                                                                className={styles["item-detail"]}
                                                                                target='_blank'
                                                                                rel='noopener noreferrer'
                                                                            >
                                                                                {formatLink(ua.visitlink)}
                                                                            </a>
                                                                        </div>
                                                                        <div className={styles["item-timestamp"]}>{ua.visitTime}</div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </>
                        )}
                    </>

                )}


            </div>
        </div>
    )
}