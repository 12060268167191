// Signup.jsx
import React, { useState, useEffect } from 'react';
import styles from './Signup.module.css'; // Import your CSS module
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import StepperComponent from './Signup_components/StepperComponent.js';
import comcatTextlogo from "../../Img/comcatTextlogo.png"
import { Auth } from 'aws-amplify';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FormComponent from './Signup_components/FormComponent.js';
import { createUser } from '../../Service/API.js';
import SignupTopNav from '../../Components/Signup_topNav/SignupTopNav.js';


function Signup(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
    password: '',
    companyName: '',
    companyURL: '',
    scriptText: '',
  });
  const [error, setError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [widgetId, setWidgetId] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [openCreateAccount, setOpenCreateAccount] = React.useState(false);
  const [accountCreated, setAccountCreated] = React.useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const steps = ['Personal Info', 'Company Info', 'Script Tag'];

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const generateRandomStringWithTimestamp = (length) => {
    const characters = 'ABCDEFGHIJ1234KLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const timestamp = new Date().getTime().toString();
    let result = '';

    // Generate random characters
    for (let i = 0; i < length - timestamp.length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result + timestamp;
  };


  const handleAccountCreation = () => {
    setAccountCreated(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAccountCreated(false);
    setUnsavedChanges(false);
  };

  const handleFieldChange = (field, value) => {
    setUnsavedChanges(true);
    setFormFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (activeStep === 0 && !formFields.email.match(emailRegex)) {
      setErrorEmail('Please enter a valid email address.');
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{6,}$/;
    if (activeStep === 0 && !formFields.password.match(passwordRegex)) {
      setError(
        'Password requirements not satisfied.'
      );
      return;
    }

    setError('');

    let updatedScript = `<script type="text/javascript" id="yourChatbot">var $yourChatbot = $yourChatbot || {};$yourChatbot.settings = {widgetid: "${widgetId}",};var d = document;var s = d.createElement("script");s.type = "text/javascript";s.id = "yourChatbotScript";s.defer = true;s.src = "https://656e42dd5e8fba1ea22a30e6--lively-pie-12e6eb.netlify.app/chatbot-bundle.js";var t = d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s, t);</script>`;

    if (activeStep === 0) {
      const randomStringWithTimestamp = generateRandomStringWithTimestamp(32);
      console.log("randomStringWithTimestamp", randomStringWithTimestamp)
      setWidgetId(randomStringWithTimestamp)
    }

    if (activeStep === 1) {
      setFormFields((prevFields) => ({
        ...prevFields,
        scriptText: updatedScript,
      }));
      setOpenCreateAccount(true);
      await handleCreateUser(widgetId, updatedScript);
      setTimeout(() => {
        setOpenCreateAccount(false);
        handleNext();
        handleAccountCreation();
      }, 2000);
    } else if (activeStep === 2) {
      try {
        handleOpen();
        const email = formFields.email;
        const password = formFields.password;
        const user = await Auth.signIn(email, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const newPassword = password;
          const updatedUser = await Auth.completeNewPassword(user, newPassword);
          if (updatedUser) {
            const user = await Auth.signIn(email, newPassword);
            const attributes = user.attributes;
            props.submit(email, attributes);
            handleClose();
          }
        } else {
          const attributes = user.attributes;
          props.submit(email, attributes);
          handleClose();
        }
      } catch (error) {
        console.error('Error signing in:', error);
      }
    } else {
      handleNext();
    }
  };

  const handleCopyScriptText = () => {
    const scriptText = formFields.scriptText;
    navigator.clipboard.writeText(scriptText)
      .then(() => {
        console.log('Script text copied to clipboard');
      })
      .catch((error) => {
        console.error('Unable to copy script text to clipboard', error);
      });

    setSnackbarMessage('Text has been copied to the clipboard!');
    setIsSnackbarOpen(true);


    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 2000); // Set the duration in milliseconds
  };

  const handleCreateUser = async (widgetId, updatedScript) => {
    try {
      const userData = {
        name: formFields.name,
        email: formFields.email,
        password: formFields.password,
        companyURL: formFields.companyURL,
        companyName: formFields.companyName,
        widgetId: widgetId,
        updatedScript: updatedScript,
      };

      //API
      const response = await createUser(userData);

      console.log(response.message);
    } catch (error) {
      console.error('Error:', error.message);
    }
  };


  return (
    <div className={styles.Signup_wrapper}>
      <SignupTopNav />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={accountCreated}
        autoHideDuration={12000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Your account has been successfully created!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={5000} // Adjust the duration as needed
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          variant="filled"
          severity="info"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCreateAccount}
      >
        <CircularProgress color="inherit" />
      </Backdrop>



      <div className={styles.Signup}>
        <div className={styles['policy-terms-container']}>
          <p>Terms of Use</p>
          <span> | </span>
          <p>Privacy Policy</p>
        </div>

        {/* <div>
          <img className={styles['logo-stampa']} src={comcatTextlogo} alt="Logo" />
        </div> */}
        <div className={styles['heading-text-style']}>
          <span className={styles['Create-account']}>Create your account</span>
          {/* <p className={styles['Register-account']}>Register an account, Come and join us</p> */}
        </div>

        <div className={styles.Stepper}>
          <StepperComponent activeStep={activeStep} steps={steps} />
        </div>


        <form className={styles['form-signup']} onSubmit={handleSubmit}>
          <FormComponent
            activeStep={activeStep}
            formFields={formFields}
            handleFieldChange={handleFieldChange}
            errorEmail={errorEmail}
            error={error}
            handleBack={handleBack}
            handleCopyScriptText={handleCopyScriptText}
            handleSubmit={handleSubmit}
          />
        </form>
      </div>
    </div>

  );
}

export default Signup;
