// MenuSideBar.js
import React from 'react';
import styles from './menuSideBar.module.css';
import comcatLogoOrange from '../../Img/comcatLogoOrange.png';
import HomeIcon from '../../Components/MenuSidebar/Icons/HomeIcon';
import { LiveIcon } from '../../Components/MenuSidebar/Icons/LiveIcon';
import { ActiveChatsIcon } from './Icons/ActiveChatsIcon';
import { HistoryIcon } from './Icons/HistoryIcon';
import { AiBotIcon } from './Icons/AiBotIcon';
import { SettingsIcon } from './Icons/SettingsIcon';
import { TbUsers } from 'react-icons/tb';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import { useGlobalVariablesContext } from '../../Service/GlobalVariables';
import { useUser } from '../../Service/SelectedUserContext';


function MenuSideBar({ page }) {

    

    const location = useLocation();
    const { selectedUser, setSelectedUser } = useUser();
    const {
        showIndicatorNewUser,
        showIndicatorNewMessage,
        toggleIndicatorNewUser,
        toggleIndicatorNewMessage } = useGlobalVariablesContext();

    const iconMap = {
        Dashboard: { route: 'dashboard', tooltip: 'Dashboard', icon: <HomeIcon fill="white" /> },
        LiveView: { route: 'liveview', tooltip: 'Live View', icon: <LiveIcon fill="white" /> },
        ActiveChat: { route: 'activechat', tooltip: 'Active Chats', icon: <ActiveChatsIcon fill="white" /> },
        History: { route: 'history', tooltip: 'History', icon: <HistoryIcon fill="white" /> },
        Visitor: { route: 'visitor', tooltip: 'Visitor', icon: <TbUsers style={{ color: 'white' }} /> },
        BotFlow: { route: 'botflow', tooltip: 'Bot Flow', icon: <AiBotIcon fill="white" /> },
    };

    const handleClick = () => {
        setSelectedUser(null);
    };


    const textStyle = { color: page === 'Dashboard' ? '#e3e3e3' : '#e3e3e3' };

    return (
        <nav className={`${styles['MenuSideBar']}`} style={{ position: 'relative' }}>
            <div className={styles.top_logo}>
                <img style={{ width: '32px' }} src={comcatLogoOrange} alt="Logo" />
            </div>

            <ul className={`${styles['ulLiSide']} `}>
                {Object.keys(iconMap).map((pageName) => (
                    <Tooltip title={<div style={{ fontSize: "15px" }}>{iconMap[pageName].tooltip}</div>} key={pageName} arrow placement="right" sx={{ backgroundColor: 'white' }}>
                        <li className={styles.icoIcon} id={iconMap[pageName].route} onClick={() => {
                            if (iconMap[pageName].route === 'activechat') {
                                toggleIndicatorNewMessage(); // Remove red dot for ActiveChat
                            } else if (iconMap[pageName].route === 'liveview') {
                                toggleIndicatorNewUser(); // Remove red dot for LiveView
                            }
                        }}>
                            <Link onClick={handleClick}  to={iconMap[pageName].route==="history"? `/${iconMap[pageName].route}/allchats` : `/${iconMap[pageName].route}`} className={location.pathname.includes(`/${iconMap[pageName].route}`) ? styles.activeclass : styles.iconHover}>
                                <div className={styles.iconContainer}>
                                    {iconMap[pageName].icon}
                                    {iconMap[pageName].route === 'activechat' && showIndicatorNewMessage && <div className={styles.redDot}></div>}
                                    {iconMap[pageName].route === 'liveview' && showIndicatorNewUser && <div className={styles.redDot}></div>}
                                </div>
                            </Link>
                        </li>
                    </Tooltip>
                ))}

            </ul>

            <Tooltip title={<div style={{ fontSize: "14px" }}>Settings</div>} arrow placement="right" sx={{ backgroundColor: 'white' }}>
                <div className={`${styles.icoIcon} ${styles.settings}`} id="settings">
                    <div className={location.pathname.includes('/settings') ? styles.activeclass : styles.iconHover}>

                        <Link to={`/settings`} className={location.pathname.includes('/settings') ? styles.activeclass : styles.iconHover}>

                            <div className={styles.iconContainer}>
                                <SettingsIcon fill="white" />
                            </div>
                        </Link>
                    </div>
                    <p className={styles.liText} style={textStyle}>
                        Settings
                    </p>
                </div>
            </Tooltip>
        </nav>
    );
}

export default MenuSideBar;