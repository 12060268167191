import { CgPassword } from "react-icons/cg";
import styles from "../forgotPassword.module.css"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export default function PasswordResetComponent({passwordFields, handlePasswordChange, handlePasswordSubmit,  passwordStrength, setPasswordStrength, loading}){
    const navigate = useNavigate()
    const [passwordContainsNumber, setPasswordContainsNumber] = useState(false);
    const [passwordContainsUpperCase, setPasswordContainsUpperCase] = useState(false);
    const [passwordContainsSpecialChar, setPasswordContainsSpecialChar] = useState(false);
    const [lengthCondition, setLengthCondition] = useState(false)


    useEffect(() => {

        if (passwordFields.newPassword.length >= 6) {
            setLengthCondition(true)
        } else {
            setLengthCondition(false)
        }
        console.log("Length : ", lengthCondition, passwordFields.newPassword)

        const containsNumber = /\d/.test(passwordFields.newPassword);
        setPasswordContainsNumber(containsNumber);

        const containsUpperCase = /[A-Z]/.test(passwordFields.newPassword);
        setPasswordContainsUpperCase(containsUpperCase);


        const containsSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(passwordFields.newPassword);
        setPasswordContainsSpecialChar(containsSpecialChar);
        const strength = (containsNumber + containsUpperCase + containsSpecialChar + lengthCondition) / 4;
        setPasswordStrength(strength);
    }, [passwordFields]);

    return (
        <div className={styles.email_container}>
            <div className={styles.top_section}>
                <div className={styles.icon_container}>
                    <span className={styles.icon}>
                        <CgPassword style={{ width: "20px", height: "20px" }} />
                    </span>
                </div>
                <div className={styles.top_section_text}>
                    <p className={styles.forgot_password_p}>Set new password</p>
                    <p style={{ color: "#8E8EA0", fontSize: "17px", textAlign: "center" }}>Must be at least 6 characters long</p>
                </div>
            </div>
            <div className={styles.bottom_section}>
                <div className={styles.email_field_container}>
                    <label htmlFor="" style={{ fontSize: "15px" }}>New Password</label>
                    <input className={styles.input_field} placeholder="Enter New Password" type="text" name="newPassword" value={passwordFields.newPassword} onChange={(e) => handlePasswordChange(e.target.name,e.target.value)} />
                </div>
                <div className={styles.email_field_container}>
                    <label htmlFor="" style={{ fontSize: "15px" }}>Confirm Password</label>
                    <input className={styles.input_field} placeholder="Enter Confirm Password" type="text" name="confirmPassword" value={passwordFields.confirmPassword} onChange={(e) => handlePasswordChange(e.target.name,e.target.value)} />
                </div>
                <div style={{ marginTop: "-10px" }}>
                    <ul style={{listStyleType:"none", fontSize:"small"}}>
                        <li style={{ color: lengthCondition ? '#b8b8b8' : '#000' }}>Be at least 6 characters long</li>
                        <li style={{ color: passwordContainsNumber ? '#b8b8b8' : '#000' }}>Contain at least one number</li>
                        <li style={{ color: passwordContainsUpperCase ? '#b8b8b8' : '#000' }}>Include both uppercase and lowercase characters</li>
                        <li style={{ color: passwordContainsSpecialChar ? '#b8b8b8' : '#000' }}>Include Special Character</li>
                    </ul>
                </div>
                <div>
                    <Button sx={{ backgroundColor: '#F27340' }} onClick={handlePasswordSubmit} variant="contained" fullWidth>Submit</Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div className={styles.login_btn_container} onClick={() => navigate("/login")}>
                        <IoIosArrowRoundBack style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>Back to log in</span>
                    </div>
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}