import React, { useState, useEffect, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { QuestionIcon } from '../LiveIcons/QuestionIcon';
import { TbWorld } from "react-icons/tb";
import LaptopIcon from '@mui/icons-material/Laptop';
import slash_50 from "../LiveIcons/slash-50.png"
import styles from "./basicTable.module.css"
import { OnlineStatusIcon } from '../LiveIcons/OnlineStatusIcon';
import CountryFlag from 'react-country-flag';
import { Avatar } from '@mui/material';
import socket from '../../../socketjs';
import chrome from '../../../Img/chrome.png';
import safari from '../../../Img/safari.png';
import microsoft from '../../../Img/microsoft.png';
import firefox from '../../../Img/firefox.png';
import { useNavigate } from 'react-router-dom';
import { useConnectedUsersContext } from '../../../Service/ConnectedUsersContext';
import { useAuth } from '../../../Service/AuthContext';
import { FaUser } from 'react-icons/fa';
import * as api from '../../../Service/API'; // Import your API functions
import { IoReturnDownForward } from "react-icons/io5";
import { BsChatRightDotsFill } from "react-icons/bs";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaLongArrowAltUp } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }

// const rows = [
//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),

//     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//     createData('Eclair', 262, 16.0, 24, 6.0),
//     createData('Cupcake', 305, 3.7, 67, 4.3),
//     createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];


export default function BasicTable({ selectedFilter }) {
    const [currentTime, setCurrentTime] = useState(new Date());
    const { connectedUsers, updateConnectedUsers } = useConnectedUsersContext();
    const { widgetID, userAttributes } = useAuth();
    const [sortOrder, setSortOrder] = useState('desc'); // 'desc' for descending, 'asc' for ascending

    const navigate = useNavigate();

    console.log("userAttributes", userAttributes)

    const handleStartChat = async (user) => {
        const updatedUsers = connectedUsers.map((connectedUser) => {
            if (connectedUser.sessionID === user.sessionID) {
                return { ...connectedUser, isActiveChat: true, adminAssigned: userAttributes.name };
            }
            return connectedUser;
        });

        console.log("updatedUsers", updatedUsers);

        socket.emit('chatStarted', {
            updatedUsers,
            sessionID: user.sessionID,
            adminAssigned: userAttributes.name,
            username: userAttributes.email,
            widgetID: widgetID,
        });

        updateConnectedUsers(updatedUsers);
        const userToActiveChat = user
        navigate('/activechat', { state: { userToActiveChat } });
    };



    const getBrowserIcon = (browserName) => {
        let icon = null;
        switch (browserName.toLowerCase()) {
            case 'chrome':
                // icon = chorome_48;
                // break;
                return <img src={chrome} className={styles.icon} alt="Chrome" />;
            case "edge":
                return <img src={microsoft} className={styles.icon} alt="Edge" />;
            case "safari":
                return <img src={safari} className={styles.icon} alt="Safari" />;
            case "firefox":
                return <img src={firefox} className={styles.icon} alt="Firefox" />;
            default:
                return <img src={chrome} className={styles.icon} alt="Chrome" />; // You can use any other icon for unknown browsers
        }
        // return <FontAwesomeIcon className={styles.icon} icon={icon} style={{ color: '#4285F4' }} />;
        return icon
    }


    // const extractLastSegment = (url) => {

    //     console.log("url===>>>>",url)

    //     const path = new URL(url).pathname;
    //     if (path !== '/') {
    //         return `/${path.substring(1)}`;
    //     } else {
    //         return new URL(url).hostname;
    //     }
    // };



    const uniqueList = useMemo(
        () =>
            connectedUsers
                .filter((obj, index, self) => index === self.findIndex((o) => o.key === obj.key))
                .filter((obj) => obj.userID),
        [connectedUsers]
    );

    useEffect(() => {

        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);


        return () => clearInterval(intervalId);
    }, []);

    const formatTimeDifference = (timestamp) => {
        const now = currentTime;
        const landingTime = new Date(timestamp);
        const timeDifferenceInSeconds = Math.floor((now - landingTime) / 1000);

        if (timeDifferenceInSeconds < 60) {
            return `${Math.max(timeDifferenceInSeconds, 10)} seconds ago`;
        } else if (timeDifferenceInSeconds < 3600) {
            const minutes = Math.floor(timeDifferenceInSeconds / 60);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else {
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        }
    };


    const renderTableContent = () => {

        let filteredList = uniqueList;

        if (selectedFilter === 'Returning') {
            filteredList = filteredList.filter((user) => user.VisitCounter > 0);
        } else if (selectedFilter === 'Assigned') {
            filteredList = filteredList.filter((user) => user.adminAssigned !== 'N/A');
        } else if (selectedFilter === 'Unassigned') {
            filteredList = filteredList.filter((user) => user.adminAssigned === 'N/A');
        }


        const sortedList = filteredList.slice().sort((a, b) => {
            const timestampA = new Date(a.timestamp).getTime();
            const timestampB = new Date(b.timestamp).getTime();

            return sortOrder === 'desc' ? timestampB - timestampA : timestampA - timestampB;
        });


        if (sortedList.length === 0) {
            return (
                <TableRow>
                    <TableCell colSpan={7} align="center">
                        No users online at the moment
                    </TableCell>
                </TableRow>
            );
        }

        return sortedList.map((row) => (
            <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell >
                    {/* <OnlineStatusIcon /> */}
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <Avatar sx={{ bgcolor: "#223952", width: "35px", height: "35px" }}>{row.username.charAt(0).toUpperCase()}</Avatar>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: '#00cd00',
                                    boxShadow: "1px 1px 7px 0 rgba(101, 101, 101, 0.2), 1px 1px 7px 0 rgba(100, 100, 100, 0.19)",
                                    borderRadius: '50%',
                                }}
                            />
                        </div>
                        <div className={styles.username_container}>
                            <span className={styles.username}>{row.username}</span>
                            {row.VisitCounter > 0 ? (
                                <span className={styles.visits_status}><IoReturnDownForward /> Returning</span>
                            ) : null}

                        </div>
                    </div>


                </TableCell>
                <TableCell className={styles.visit_time}>{formatTimeDifference(row.timestamp)}</TableCell>
                <TableCell>
                    {<div className={styles.visit_info}>
                        {row.country_code2 && <CountryFlag className={styles.icon} countryCode={row.country_code2} svg />}
                        {/* <img className={styles.icon} src={getBrowserIcon(row.browser.split(" ")[0])} alt="" /> */}
                        {getBrowserIcon(row.browser.split(" ")[0])}
                        {/* Render something else if the country code is not available */}
                    </div>}
                </TableCell>
                <TableCell className={styles.visit_link}>
                    <a href={row.visitlink} target="_blank" rel="noopener noreferrer">
                        {/* {extractLastSegment(row.visitlink)} */}

                        {row.visitlink}
                    </a>
                </TableCell>
                <TableCell>
                    {row.adminAssigned === "N/A" ? (
                        <p>N/A</p>
                    ) : (
                        <>
                            <div className={styles.assigned_Operator}>
                                <FaUser className={styles.user_icon} />
                                {row.adminAssigned}
                            </div>

                        </>
                    )}
                </TableCell>

                <TableCell style={{ textAlign: "-webkit-left" }} align='left'>
                    {(row.isActiveChat || row.isChatClosed) && (
                        <button
                            className={row.isActiveChat ? styles.inActive_chat_btn : row.isChatClosed ? styles.close_chat_btn : null}
                            disabled={true}
                        >
                            {/* {!row.isActiveChat && (<div><BsChatRightDotsFill style={{ fontSize: "15px", color: "ffffff" }} /> </div>)} */}
                            {row.isActiveChat ? 'In Active Chat' : row.isChatClosed ? "Chat Closed" : null}
                        </button>
                    )}
                    {(!row.isActiveChat && !row.isChatClosed) && (
                        <p>N/A</p>
                    )}


                </TableCell>
                <TableCell style={{ textAlign: "-webkit-right" }} align='right'>
                    {!row.isActiveChat && (
                        <button
                            onClick={() => {
                                if (!row.isActiveChat) {
                                    handleStartChat(row);
                                }
                            }}
                            className={row.isActiveChat ? styles.inActive_chat_btn : styles.start_chat_btn}
                            disabled={row.isActiveChat}
                        >
                            {/* {!row.isActiveChat && (<div><BsChatRightDotsFill style={{ fontSize: "15px", color: "ffffff" }} /> </div>)} */}
                            {row.isChatClosed ? "Restart Chat" : "Start Chat"}
                        </button>
                    )}



                </TableCell>
                
            </TableRow>
        ));
    };



    return (
        <TableContainer component={Paper} className={styles.table_container} style={{ maxHeight: "65vh",borderRadius:"15px" }}>
            <Table  stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead >
                    <TableRow>
                        <TableCell style={{ width: '18%' }} className={styles.table_heading}>Name</TableCell>
                        <TableCell style={{ width: '13%' }} onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}>
                            Entered
                            {sortOrder === 'desc' ? <FaLongArrowAltDown style={{ marginBottom: "3px", color: "#595959" }} /> : <FaLongArrowAltUp style={{ marginBottom: "3px", color: "#595959" }} />}
                        </TableCell>

                        <TableCell style={{ width: '11%' }} ><TbWorld className={styles.icon} /><img src={slash_50} alt="slash" className={styles.slash_icon} /><LaptopIcon className={styles.icon} /></TableCell>
                        <TableCell style={{ width: '20%' }} className={styles.table_heading}>Visited Page</TableCell>
                        <TableCell style={{ width: '15%' }} className={styles.table_heading}>Assigned Operator</TableCell>
                        <TableCell style={{ width: '12%' }} className={styles.table_heading}>Status</TableCell>
                        <TableCell style={{ width: '12%' }} className={styles.table_heading}></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderTableContent()}
                </TableBody>
            </Table>
        </TableContainer>
    );
}